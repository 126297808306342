export const WHITE = 0xffffff;
export const BLACK = 0x000000;
export const POINT_COLOR = 0x1fdb9b;
export const SELECTED_POINT_COLOR = 0x0f6d4d;
export const MIDPOINT_COLOR = 0x23e7a5;
export const MEASUREMENT_POINT_COLOR = 0x0a6043;
export const COORDINTATE_LINE_COLOR_X = 0xd1d8f9;
export const COORDINTATE_LINE_COLOR_Y = 0xb3f5ed;
export const ZOOM_POINT_COLOR = 0x099483;
export const RESTRICTED_AREA_COLOR = 0xe43f32;
export const MOUNTING_SURFACE_COLOR = 0x7e8884;
export const SOLAR_POINT_COLOR = 0xe8e9e9;
export const MARGIN_COLOR = 0x6cb0ff;
export const SELECTED_LINE_COLOR = 0xffdd79;
export const OUTLINR_COLOR = 0xfff5d4;

export const ZOOM_FACTOR = -30;

export const VERTICAL = "vertical";
export const HORIZONTAL = "horizontal";
export const SOLAR_GROUP = "SOLAR_GROUP"
export const AREA = "AREA"
export const AVAILABLE_LOCALES = ["de", "en"];

export const RENDERING_ORDER = {
  SOLAR_PLANE: 3,
  INNER_SOLAR_PLANE: 2,
  PANEL: 9999,
  INDIVIDUAL_PANEL: 12000,
  INDIVIDUAL_PANEL_OUTLINE: 15000,
  PANEL_TRASH: 15000,
  MEASUREMENT_PLANE: 20000,
  AUXILIARY_LINE: 25000,
  LINE: 30000,
  OUTER_POINT: 35000,
  INNER_POINT: 40000,
  MEASUREMENT_LABEL: 45000,
  MEASUREMENT_AREA_LABEL: 50000,
  MOVE_GRID: 55000,
  ZOOM_CENTER: 60000,
};

export const DEFAULT_HORIZONTAL_SPACING = 5;
export const DEFAULT_VERTICAL_SPACING = 5;
export const DEFAULT_MARGIN = 10;
export const DEFAULT_OFFSET = 5;

export const TEST_COMPANIES = [
  82, 101, 296, 345, 370, 483, 487, 563, 751, 1069, 1650, 2013, 2252, 2326,
  2416, 2614, 2615, 2630, 2766, 2963, 3009, 3016, 3085, 3556, 3903, 3981,
];
