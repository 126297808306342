<template>
  <div class="wrapper flex justify-center align-items-center" v-if="loading">
    <div class="progress-container">
      <p class="q-mb-md">
        <img
          class="airteam-logo"
          src="@/assets/model/airteam_transparent.svg"
        />
        AIRTEAM
      </p>
      <q-linear-progress
        :value="loadingProgress"
        size="md"
        color="blue"
        rounded
        track-color="white"
      />
      <p class="progress-hint q-mt-md">Loading 3D model</p>
    </div>
  </div>

  <div v-show="!loading" class="viewer-wrapper row">
    <div v-show="!sidebarOpen" class="col-p5">
      <span class="open-sidebar" @click="toggleSidebar($event)">
        <div>
          <img src="@/assets/icons/chevron-right.svg" />
        </div>
      </span>
    </div>
    <div
      v-show="sidebarOpen"
      class="col-lg-2 col-md-3 col-sm-4 col-xs-12 project-panel q-pa-lg new-sidebar-layout"
      style="padding: 0"
      @click="closeEditMode"
    >
      <q-breadcrumbs class="q-py-sm q-px-md breadcrumbs__container">
        <template v-slot:separator>
          <q-icon size="1.5em" name="chevron_right" color="white"></q-icon>
        </template>

        <q-breadcrumbs-el style="cursor: pointer" @click="backtoProjects">
          {{ $t("sidebar.projects") }}</q-breadcrumbs-el
        >
        <q-breadcrumbs-el @click="backToProjectPage" style="cursor: pointer"
          >ID {{ projectId }}</q-breadcrumbs-el
        >
        <q-breadcrumbs-el
          :label="$t('sidebar.3dmodel')"
          class="breadcrumbs__last"
        />
      </q-breadcrumbs>
      <div class="sidebar-top q-pa-lg">
        <div v-if="!sample" class="project-panel__title">
          <p class="project-panel__title-value">
            <img
              style="margin-bottom: 5px"
              class="q-mr-sm"
              src="@/assets/icons/locationGreen.svg"
            />
            {{ projectAddress }}
          </p>
        </div>
        <p v-else class="project-panel__id">
          {{ $t("sidebar.sampleProject") }}
        </p>
      </div>
      <div class="sidebar-bottom" style="padding: 2px 24px 0 24px">
        <q-expansion-item
          v-model="summaryExpanded"
          :header-class="`summary__header ${
            summaryExpanded ? 'summary__header--expanded' : ''
          }`"
          :label="$t('sidebar.summary')"
          :caption="$t('canvas.unitOfMeasurement')"
          :expand-separator="false"
        >
          <q-card
            class="summary__card"
            :class="summaryExpanded && 'summary__card--expanded'"
          >
            <q-card-section>
              <div class="summary__content">
                <p class="project-panel__field q-mb-sm">
                  <span class="project-panel__field--label">{{
                    $t("sidebar.totalRoofArea")
                  }}</span>
                  <span class="project-panel__field--value"
                    >{{ totalRoofArea }}m²</span
                  >
                </p>
                <p class="project-panel__field q-mb-sm">
                  <span class="project-panel__field--label">{{
                    $t("sidebar.numberOfPanels")
                  }}</span>
                  <span class="project-panel__field--value">{{
                    totalNumberOfPanels
                  }}</span>
                </p>
                <p class="project-panel__field q-mb-sm">
                  <span class="project-panel__field--label">{{
                    $t("sidebar.totalPanelsArea")
                  }}</span>
                  <span class="project-panel__field--value"
                    >{{ totalPanelsArea }}m²</span
                  >
                </p>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <div style="margin: 15px 0">
          <a
            v-if="isBetaEnvironmentAndIsEmployee"
            style="
              cursor: pointer;
              color: white;
              text-decoration: underline;
              font-size: 15px;
            "
            @click="handleNavigateToOldPlacements"
            >{{ $t("sidebar.switchToOldPlacements") }}</a
          >
        </div>
        <q-expansion-item
          v-if="activeUserIsEmployee"
          v-model="orientationCardExpanded"
          :header-class="`bg-white additional-deliverables__header ${
            orientationCardExpanded
              ? 'additional-deliverables__header--expanded'
              : ''
          }`"
          label="Model Orientation"
          style="color: black; font-size: 12px"
        >
          <q-card
            class="additional-deliverables__card"
            :class="
              orientationCardExpanded &&
              'additional-deliverables__card--expanded'
            "
          >
            <q-card-section>
              <div class="orientation-values">
                <q-slider
                  v-model="orientationX"
                  :min="-180"
                  :max="180"
                  :step="1"
                  label
                  color="primary"
                  @update:model-value="orientationUpdated"
                />
                <q-input
                  dense
                  filled
                  type="number"
                  suffix="&deg;"
                  v-model="orientationX"
                  label="X"
                  @update:model-value="orientationUpdated"
                />
              </div>

              <div class="orientation-values">
                <q-slider
                  v-model="orientationY"
                  :min="-180"
                  :max="180"
                  :step="1"
                  label
                  color="primary"
                  @update:model-value="orientationUpdated"
                />
                <q-input
                  dense
                  filled
                  type="number"
                  suffix="&deg;"
                  v-model="orientationY"
                  label="Y"
                  @update:model-value="orientationUpdated"
                />
              </div>

              <div class="orientation-values">
                <q-slider
                  v-model="orientationZ"
                  :min="-180"
                  :max="180"
                  :step="1"
                  label
                  color="primary"
                  @update:model-value="orientationUpdated"
                />
                <q-input
                  dense
                  filled
                  type="number"
                  suffix="&deg;"
                  v-model="orientationZ"
                  label="Z"
                  @update:model-value="orientationUpdated"
                />
              </div>
              <q-btn
                no-caps
                color="primary"
                class="add-panels q-mt-sm"
                @click="saveOrientation"
                >Save Orientation
              </q-btn>
            </q-card-section>
          </q-card>
        </q-expansion-item>
        <hr class="project-panel__line" />
        <q-expansion-item
          v-if="activeUserIsEmployee"
          v-model="scaleCardExpanded"
          :header-class="`bg-white additional-deliverables__header ${
            scaleCardExpanded ? 'additional-deliverables__header--expanded' : ''
          }`"
          label="Scale Model"
          style="color: black"
        >
          <q-card
            class="additional-deliverables__card"
            :class="
              scaleCardExpanded && 'additional-deliverables__card--expanded'
            "
          >
            <q-card-section>
              <div class="">
                <q-input
                  dense
                  filled
                  type="number"
                  v-model="scale"
                  label="Scale"
                  @update:model-value="scalingUpdated"
                />
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <hr class="project-panel__line" />

        <MainSidebar
          ref="mainSidebaRef"
          :sample="sample"
          :solarGroupDetails="areas"
          :distancesDetails="measurements"
          :areaDetails="measurementAreas"
          :completedAreas="completedAreas"
          :hideAreas="hideAreas"
          :showAreas="showAreas"
          :measurementAreaCreated="measurementAreaCreated"
          :showSingleArea="showSingleArea"
          :hideSingleArea="hideSingleArea"
          :hideAllMeasurementAreas="hideAllMeasurementAreas"
          :selectAreaFromSidebar="selectAreaFromSidebar"
          :showAllMeasurementAreas="showAllMeasurementAreas"
          :hideMeasurements="hideMeasurements"
          :showMeasurements="showMeasurements"
          :updateTransparencyLevel="updateTransparencyLevel"
          @updateSelectedArea="updateSelectedArea"
          @updateSelectedDistance="updateSelectedDistance"
          :projectId="projectId"
          :objectIsloaded="objectIsloaded"
          :deleteMeasurement="deleteMeasurement"
          :selectedFromSidebarEvent="selectedFromSidebarEvent"
          :removeSelectedMeasurementArea="removeSelectedMeasurementArea"
          :removeSolarGroup="removeSolarGroup"
          :detectedMeasurementArea="detectedMeasurementArea"
          :unselectFromSidebar="unselectFromSidebar"
          :unselectSolarArea="unselectSolarArea"
          :anonymousUser="anonymousUser"
          :totalNumberOfPanels="totalNumberOfPanels"
          :totalPanelsArea="totalPanelsArea"
          :active="active"
          @updateActive="updateActive"
          :selectedMeasurementArea="selectedMeasurementArea"
          :unselectMeasurementArea="unselectMeasurementArea"
        />

        <hr v-if="activeUserIsEmployee" class="project-panel__line" />
        <q-scroll-area
          v-if="niraId"
          :class="
            orientationCardExpanded
              ? 'height-75'
              : completedAreas.length > 0
              ? 'height-250'
              : 'height-75'
          "
        >
          <hr v-if="niraId && completedAreas.length > 0" />
          <q-btn
            v-if="niraId"
            no-caps
            outline
            color="white"
            class="ff3d-btn q-mt-sm"
            @click="switchToNira"
            style="border: 1px solid #23e7a5"
            >{{ $t("sidebar.switchToOldViewer") }}</q-btn
          >
        </q-scroll-area>
        <hr v-if="activeUserIsEmployee" class="project-panel__line" />
        <q-btn
          no-caps
          style="background: #23e7a5; color: black"
          class="report-button q-mt-sm"
          :disabled="totalNumberOfPanels === 0"
          @click="printPage"
          >{{ $t("sidebar.createReport") }}

          <q-tooltip class="report-tooltip" v-if="totalNumberOfPanels === 0">
            {{ $t("sidebar.createReportInfo") }}
          </q-tooltip>
        </q-btn>
        <ExcelExport
          v-if="!anonymousUser"
          :project-id="projectId"
          :measurement-areas="measurementAreas"
          :measurements="measurements"
          :areas="areas"
          :panel-types="[
            ...verticalPanelTypes,
            ...horizontalPanelTypes,
            ...customPanels,
          ]"
        />
      </div>
    </div>
    <div
      id="airteam-viewer"
      ref="airteamViewerDiv"
      class="airteam-viewer"
      :class="
        sidebarOpen ? 'col-lg-10 col-md-9 col-sm-8 hide-mobile' : 'col-115'
      "
    >
      <div
        v-show="active !== 2 && active !== 3 && active !== 6"
        class="position0 q-pa-sm q-ml-sm hide-mobile"
      >
        <div
          class="undo-redo q-mx-sm"
          :class="undoStack.length === 0 ? 'cursor-disabled' : 'cursor-pointer'"
        >
          <img
            class="undo-arrow"
            src="@/assets/icons/left-arrow.svg"
            @click="undo"
          />
        </div>
        <div
          class="undo-redo q-mx-sm"
          :class="redoStack.length === 0 ? 'cursor-disabled' : 'cursor-pointer'"
        >
          <img
            class="undo-arrow"
            src="@/assets/icons/right-arrow.svg"
            @click="redo"
          />
        </div>
      </div>
      <div class="position1" id="right-side-bar">
        <div class="screenshot-bg">
          <span
            class="q-mr-sm hide-mobile"
            :class="active !== 0 ? 'cursor-disabled' : 'cursor-pointer'"
          >
            <img
              style="padding-top: 10px"
              src="@/assets/icons/screenshot-button.svg"
              @click="takeScreenshot"
            />
          </span>

          <span v-if="!anonymousUser" class="q-mr-xs">
            <span class="icon">
              <img
                v-if="$i18n.locale === 'de'"
                src="@/assets/icons/share-button-de.svg"
                @click="shareSolarModel"
              />
              <img
                v-else
                src="@/assets/icons/share-button.svg"
                @click="shareSolarModel"
              />
            </span>
          </span>
        </div>
        <SolarSidebar
          v-if="selectedArea && selectedArea.type === 'SOLAR_GROUP'"
          :areas="areas"
          :horizontalPanels="horizontalPanelTypes"
          :verticalPanels="verticalPanelTypes"
          :activeUserId="activeUserId"
          :selectedSolarGroup="selectedArea"
          :sample="sample"
          :populateArea="populateArea"
          :createUpdateSolarGroup="createUpdateSolarGroup"
          :removeSolarGroupPanels="removeSolarGroupPanels"
          :addOffsetToPanels="addOffsetToPanels"
          :removeSolarGroup="removeSolarGroup"
          :anonymousUser="anonymousUser"
          :likedPanel="likedPanel"
          :likedPanelId="likedPanelId"
          :starClicked="starClicked"
          :disableRestrictedAreaMode="disableRestrictedAreaMode"
          :disableIndividualPlacementMode="disableIndividualPlacementMode"
          :unlikePanel="unlikePanel"
          :loadCustomPanelTextures="loadCustomPanelTextures"
          :getUserDefaultSolarValues="getUserDefaultSolarValues"
          :active="active"
          :individualPanelOrientation="individualPanelOrientation"
          :enableIndividualPlacementMode="enableIndividualPlacementMode"
          :validateIndividualPanels="validateIndividualPanels"
          :changePanelTypeForIndividualPanels="
            changePanelTypeForIndividualPanels
          "
          @setupCompleted="unselectSolarArea"
          @restrictedAreasPlacement="() => toggleActive(6)"
          @solarGroupDeleted="unselectSolarArea"
          @defaultValuesUpdates="resyncDefaultValuesOnUpdate"
        />
      </div>
      <div v-if="logo && anonymousUser" class="position2">
        <span class="q-mr-sm">
          <img
            style="padding-top: 10px; opacity: 0.5"
            :src="logo"
            width="100"
          />
        </span>
      </div>
      <div id="panels-toolbar" class="position5 hide-mobile">
        <div class="panels__toolbar main-menu">
          <div
            class="text-center panels__toolbar-text cursor-pointer q-py-sm"
            @click="(e) => toggleActive(0)"
          >
            <img
              v-show="active !== 0"
              class="menu-icon"
              src="@/assets/icons/white-cursor.svg"
            />
            <img
              v-show="active === 0"
              class="menu-icon"
              src="@/assets/icons/green-cursor.svg"
            />
          </div>
          <img src="@/assets/icons/menu-item-line.svg" />
          <div
            class="text-center panels__toolbar-text cursor-pointer q-py-sm"
            @click="toggleActive(1)"
          >
            <img
              v-show="!measurementModeActive"
              class="menu-icon"
              src="@/assets/icons/white-measure-icon.svg"
            />
            <img
              v-show="measurementModeActive"
              class="menu-icon"
              src="@/assets/icons/green-measure-icon.svg"
            />
          </div>
          <img v-if="!anonymousUser" src="@/assets/icons/menu-item-line.svg" />
          <div
            v-if="!anonymousUser"
            class="text-center panels__toolbar-text cursor-pointer q-py-sm"
            @click="(e) => toggleActive(2)"
          >
            <img
              v-show="active !== 2 && active !== 3 && active !== 6"
              class="menu-icon"
              src="@/assets/icons/white-solar-icon.svg"
            />
            <img
              v-show="active === 2 || active === 3 || active === 6"
              class="menu-icon"
              src="@/assets/icons/green-solar-icon.svg"
            />
          </div>

          <img
            v-if="activeUserIsEmployee"
            src="@/assets/icons/menu-item-line.svg"
          />

          <div
            v-if="activeUserIsEmployee"
            class="text-center panels__toolbar-text"
          >
            <img
              v-if="!camerasDisplayed"
              @click="displayCameras"
              class="menu-icon cursor-pointer"
              src="@/assets/icons/white-cameras-icon.svg"
            />
            <img
              v-else
              @click="changeCamerasVisibility(false)"
              class="menu-icon cursor-pointer"
              src="@/assets/icons/green-cameras-icon.svg"
            />
          </div>
        </div>
      </div>
      <div
        v-if="measurementModeActive"
        class="position55 hide-mobile"
        @mouseover="measurementMenuExpanded = true"
        @mouseleave="showSmallMeasurementMenu"
      >
        <div class="panels__toolbar panels__toolbar--small main-menu--small">
          <div
            class="panels__toolbar-text panels__toolbar-text--small text-left q-mb-xs cursor-pointer"
            @click="(e) => toggleActive(1)"
          >
            <img
              v-show="active !== 1"
              class="menu-icon--small"
              src="@/assets/icons/distance.svg"
            />
            <img
              v-show="active === 1"
              class="menu-icon--small"
              src="@/assets/icons/distance_green.svg"
            />
            <span
              v-if="measurementMenuExpanded"
              class="q-ml-sm"
              :class="active === 1 ? 'menu-text--active' : ''"
              >{{ $t("modes.distance") }}</span
            >
          </div>
          <div
            class="panels__toolbar-text panels__toolbar-text--small text-left cursor-pointer"
            @click="(e) => toggleActive(4)"
          >
            <img
              v-show="active !== 4"
              class="menu-icon--small"
              src="@/assets/icons/area_2.svg"
            />
            <img
              v-show="active === 4"
              class="menu-icon--small"
              src="@/assets/icons/area_2_green.svg"
            />
            <span
              v-if="measurementMenuExpanded"
              class="q-ml-sm"
              :class="active === 4 ? 'menu-text--active' : ''"
              >{{ $t("modes.area") }}</span
            >
          </div>
        </div>
      </div>
      <div class="position9 q-pa-sm">
        <span
          class="q-mx-md cursor-pointer hide-mobile"
          @click="handleOrbitClick"
        >
          <img v-show="navigationMenu !== 0" src="@/assets/icons/orbit.svg" />
          <img
            v-show="navigationMenu === 0"
            src="@/assets/icons/orbit_green.svg"
          />
        </span>
        <span
          class="q-ml-sm q-mr-md cursor-pointer hide-mobile"
          @click="handlePanClick"
        >
          <img
            style="width: 25px"
            v-show="navigationMenu !== 1"
            src="@/assets/icons/pan.svg"
          />
          <img
            style="width: 25px"
            v-show="navigationMenu === 1"
            src="@/assets/icons/pan_green.svg"
          />
        </span>
        <img
          class="q-mx-md hide-mobile"
          src="@/assets/icons/navigation-menu-break.svg"
        />
        <span class="q-mx-sm cursor-pointer" @click="resetCameraPosition">
          <img
            style="width: 30px"
            v-show="!showGreenFullScreen"
            src="@/assets/icons/full_screen_nav.svg"
          />
          <img
            style="width: 30px"
            v-show="showGreenFullScreen"
            src="@/assets/icons/full_screen_nav-green.svg"
          />
        </span>
        <img
          class="q-mx-md hide-mobile"
          src="@/assets/icons/navigation-menu-break.svg"
        />
        <span class="q-mx-md cursor-pointer hide-mobile" @click="openHelpModal">
          <img
            class="help-button"
            src="@/assets/icons/help-icon.svg"
            alt="help"
          />
        </span>
      </div>
      <div class="position10">
        <img src="@/assets/model/metric_info.svg" class="q-mr-sm" />
        {{ $t("canvas.unitOfMeasurement") }}
      </div>
      <div id="arrow-container" class="position11"></div>
    </div>
    <AirteamViewerHelper
      :isOpen="openHelperModal"
      @helper-closed="closeHelperModal"
    />
  </div>
</template>

<script>
import * as THREE from "three";
import { mapActions, mapGetters } from "vuex";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { ElMessage } from "element-plus";
import { CSS2DRenderer } from "three/addons/renderers/CSS2DRenderer.js";
import AirteamViewerHelper from "@/components/AirteamViewerHelper.vue";
import { isProduction, isStaging } from "../../src/utils/env";
import API from "@/api/API";
import { decodeJwt } from "@/utils/decode-jwt.js";
import { event } from "vue-gtag";
import CameraControls from "camera-controls";
import { H } from "highlight.run";
import getCookie from "@/utils/get-cookie";
import * as areaMethods from "@/modules/area";
import * as restrictedAreaMethods from "@/modules/restrictedArea";
import * as undoRedoMethods from "@/modules/undoRedo";
import * as measurementMethods from "@/modules/measurement";
import * as areaMeasurementMethods from "@/modules/areaMeasurement";
import * as panelMethods from "@/modules/panel";
import * as commonMethods from "@/modules/common";
import * as authMethods from "@/modules/auth";
import * as navigationMethods from "@/modules/navigation";
import * as cameraMethods from "@/modules/camera";
import { initializeModes } from "@/modules/modes.js";
import { changeLocale } from "@/plugins/i18n";
import { WHITE, VERTICAL, HORIZONTAL, TEST_COMPANIES } from "@/constants";
// removed due to mk3 split release
import MainSidebar from "@/components/NewSolarSidebar/MainSidebar.vue";
import SolarSidebar from "@/components/RightSidebar/SolarSidebar.vue";
import newMarker from "@/assets/model/new_marker_3.svg";
import Stats from "stats.js";
import ExcelExport from "@/components/NewSolarSidebar/ExcelExport.vue"; // Import stats.js

CameraControls.install({ THREE: THREE });

export default {
  name: "AirteamViewer",
  components: {
    ExcelExport,
    AirteamViewerHelper,
    MainSidebar,
    SolarSidebar,
  },
  props: ["sample"],
  data() {
    return {
      detectedMeasurementArea: null,
      isMeasurementSelected: false,
      previousSolarArea: null,
      stencilCount: 1,

      objectIsloaded: null,
      measurementAreaCreated: null,
      likedPanel: null,
      likedPanelId: null,
      modelId: null,
      modelPath: null,
      projectId: null,
      companyId: null,
      projectFlightDate: null,
      projectAddress: null,
      model: {
        project: {},
      },
      currentProject: null,
      cameraObject: null,
      cameraControls: null,
      clock: null,
      zoomCenterDot: null,
      lightning: false,
      container: null,
      controls: null,
      renderer: null,
      labelRenderer: null,
      lighting: null,
      ambient: null,
      keyLight: null,
      fillLight: null,
      backLight: null,
      active: 0,
      mouse: null,
      spriteMaterial: null,
      dragOn: false,
      areas: [],
      measurementAreas: [],
      sidebarOpen: window.innerWidth > 600,
      areaExpanded: true,
      camerasDisplayed: false,
      cameraGroup: null,
      panelAdditionType: null,
      panelType: "",
      panelSpacing: null,
      selectedArea: null,
      panelStep: 0,
      panelSetupConfirmed: false,
      pmremGenerator: null,
      existingAreasIds: [],
      existingMeasurementsIds: [],
      neutralEnvironment: null,
      callCount: 0,
      movingLeft: true,
      isEditButtonDisplayed: false,
      pointSelected: null,
      screenshot: null,
      isCtrlDown: false,
      isAltDown: false,
      isShiftDown: false,
      combinedMatrix: null,
      startX: 0,
      startY: 0,
      currentX: 0,
      currentY: 0,
      selectionBox: null,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      measurementLine: null,
      currentMeasurementAreaLines: [],
      measurementAreaEndingLine: null,
      restrictedAreaEndingLine: null,
      panelAlignment: false,
      panelCounter: 1,
      openHelperModal: false,
      texturePath: "/assets/textures/",
      texturesLoading: true,
      trashSize: {
        width: 0.5,
        height: 0.5,
      },
      verticalPanelTypes: [],
      horizontalPanelTypes: [],
      customSizePanel: {
        width: null,
        height: null,
      },
      undoStack: [],
      redoStack: [],
      currentMeasurementLines: [],
      orientationCardExpanded: false,
      summaryExpanded: false,
      hasCalibraton: false,
      orientationX: -90,
      orientationY: 0,
      orientationZ: 0,
      scaleCardExpanded: false,
      scale: 1.0,
      rotationSpeed: 0.001,
      upperAngleRange: 0.7854, // 45 degrees in radians
      lowerAngleRange: -0.7854,
      anonymousUser: false,
      sessionStartTime: null,
      measurements: [],
      mouseOverModel: false,
      canvasElementRef: null,
      navigationMenu: null,
      showGreenFullScreen: false,
      panelsToUpdate: [],
      measurementModeActive: false,
      measurementMenuExpanded: false,
      dotGeometry: null,
      middotGeometry: null,
      activeUserisEmployee: false,
      activeUserId: null,
      timestamps: [],
      currentPixelRatio: null,
      loading: false,
      loadingProgress: 0,
      annotationRenderOrder: 0,
      solarDefaultValues: null,
      exportedManually: false,
      logo: null,
      stats: null,
      individualPanelOrientation: null,
      lastUpdatedTime: 0,
      throttleInterval: 68,
    };
  },
  watch: {
    panelSpacing() {
      if (this.panelSpacing?.includes(",")) {
        this.panelSpacing = this.panelSpacing.replace(/,/g, ".");
      }
    },
  },
  computed: {
    ...mapGetters(["customPanels"]),
    modes() {
      return initializeModes(this);
    },
    isBetaEnvironmentAndIsEmployee() {
      return this.activeUserIsEmployee && window.location.href.includes("beta");
    },
    restrictedAreaMode() {
      const mode = this.modes.find((mode) => mode.name === "RESTRICTED_AREA");
      return mode.enabled;
    },
    getViewerWidth() {
      if (this.sidebarOpen) return 0.83333;
      return 0.9167;
    },
    completedAreas() {
      return this.areas.filter((area) => area.plane);
    },
    chosenPanel() {
      return this.panelTypes.find((item) => item.name === this.panelType);
    },
    totalNumberOfPanels() {
      return this.areas
        .filter((area) => area.plane || area.instancedMesh)
        .map((area) => this.getAreaPanelsCount(area))
        .reduce((a, b) => a + b, 0);
    },
    totalRoofArea() {
      let totalArea = 0;
      for (let area of this.areas) {
        totalArea += Number(this.calculateSurfaceArea(area));
      }
      return totalArea.toFixed(2);
    },
    totalPanelsArea() {
      let totalArea = 0;
      for (let area of this.areas.filter((area) => area.plane)) {
        totalArea += this.calculateVisiblePanelsArea(area);
      }
      return totalArea.toFixed(2);
    },

    panelsDataPerSize() {
      const sizeToPanelNumber = {};
      const sizeToPanelSurface = {};
      for (const i in this.areas) {
        for (const j in this.areas[i].panels) {
          const panel = this.areas[i].panels[j];
          const size = panel.size.width + " x " + panel.size.height + " mm";
          if (size in sizeToPanelSurface) {
            sizeToPanelNumber[size] += 1;
            sizeToPanelSurface[size] += panel.size.height * panel.size.width;
          } else {
            sizeToPanelNumber[size] = 1;
            sizeToPanelSurface[size] = panel.size.height * panel.size.width;
          }
        }
      }
      return [sizeToPanelNumber, sizeToPanelSurface];
    },
    panelTypes() {
      const panelsList = this.panelAlignment
        ? this.horizontalPanelTypes
        : this.verticalPanelTypes;

      const filteredCustomPanels = this.customPanels.filter((panel) => {
        if (panel.show) {
          return this.panelAlignment
            ? panel.orientation === HORIZONTAL
            : panel.orientation === VERTICAL;
        }
      });
      return [...filteredCustomPanels, ...panelsList];
    },
    isFirstPoint() {
      if (this.areas.length === 0 || this.areas[this.areas.length - 1].closed)
        return true;
      return false;
    },
    isFirstMeasurementPoint() {
      if (
        this.measurementAreas.length === 0 ||
        this.measurementAreas[this.measurementAreas.length - 1].closed
      )
        return true;
      return false;
    },
    modelRotation() {
      return this.modelObject.children[0].rotation;
    },
    currentLeftClickAction() {
      if (this.active === 0) return CameraControls.ACTION.ROTATE;
      return CameraControls.ACTION.NONE;
    },
  },
  async created() {
    const lang = this.$route.query.lang;
    changeLocale(lang);
    this.loading = true;
    this.$store.commit("setIsSample", this.sample ? true : false);
    this.sessionStartTime = new Date();
    const token = this.$route?.query?.token;

    if (token) {
      const decodedPayload = decodeJwt(token);
      if (!decodedPayload) {
        this.showTokenInvalid();
        return;
      }
      this.modelId = decodedPayload["model_id"];
      this.modelPath = decodedPayload["model_path"];
      this.projectId = decodedPayload["project_id"];
      this.companyId = decodedPayload["company_id"];
      this.projectFlightDate = decodedPayload["project_flight_date"];
      this.projectAddress = decodedPayload["project_address"];
      this.orientationX = parseInt(decodedPayload["orientation_x"]);
      this.orientationY = parseInt(decodedPayload["orientation_y"]);
      this.orientationZ = parseInt(decodedPayload["orientation_z"]);
      this.hasCalibraton = true;
      this.scale = decodedPayload["scale"];
      this.logo = decodedPayload["logo"];
      this.anonymousUser = true;
      this.$store.commit("setIsAnonymous", true);
    } else {
      this.$store.commit("setIsAnonymous", false);

      if (!this.sample) {
        const currentProjectAsString = getCookie(
          `${isProduction ? "" : "staging"}ffsolardata`
        );
        if (!currentProjectAsString) {
          this.showTokenInvalid();
          return;
        }
        const currentProject = JSON.parse(currentProjectAsString);
        this.currentProject = currentProject;
        this.projectId = currentProject.projectId;
        this.companyId = currentProject.companyId;
        this.$store.commit("setToken", this.currentProject.token);
        H.identify(currentProject.projectId, { ...currentProject });

        if (!currentProject.token) {
          this.showTokenInvalid();
          return;
        }
        const decodedToken = decodeJwt(currentProject.token);
        this.verifyToken(decodedToken);
        this.airteamViewerToken = decodedToken;

        this.modelId = currentProject.modelId;
        this.modelPath = currentProject.modelPath;
        this.niraId = currentProject.niraId;
        this.projectId = currentProject.projectId;
        this.activeUserIsEmployee = currentProject.isEmployee;
        this.activeUserId = currentProject.activeUserId;

        this.projectFlightDate = currentProject.projectFlightDate;
        this.projectAddress = currentProject.projectAddress;
        this.scale = currentProject.scale;
        this.logo = currentProject.logo;

        let orientation = currentProject.orientation;

        if (orientation) {
          try {
            orientation = JSON.parse(orientation);
            this.orientationX = parseInt(orientation.x);
            this.orientationY = parseInt(orientation.y);
            this.orientationZ = parseInt(orientation.z);
            this.hasCalibraton = true;
          } catch (error) {
            console.log("Orientation parsing failed");
          }
        }
      }
    }

    this.getCustomPanels({ projectId: this.projectId });
    if (
      (isProduction && !TEST_COMPANIES.includes(this.companyId)) ||
      isStaging
    ) {
      event("fast_fusion_solar_entered", {
        page_title: "AirteamViewer",
        page_location: window.location.href,
        page_path: window.location.pathname,
        project_id: this.projectId,
        user: this.activeUser?.email,
        date: Date.now(),
        customer_type: this.activeUserIsEmployee ? "employee" : "customer",
      });
    }

    this.$store.commit("setProjectId", this.projectId);

    this.$store.commit("setProjectId", this.projectId);

    this.model = {
      ready: true,
      modelName: this.modelId,
      baseUrl: this.modelPath,
      project: {
        pk: this.projectId,
        flightDate: this.projectFlightDate,
        address: this.projectAddress,
      },
    };

    this.init();
    this.showBridge(1, this.model.modelName, this.model.baseUrl);
    const clock = new THREE.Clock();
    this.clock = clock;
  },
  mounted() {
    document.querySelector("body").style.paddingTop = "0";
  },
  beforeUnmount() {
    document.querySelector("body").style.paddingTop = null;
  },
  beforeRouteLeave(to, from, next) {
    this.trackPageView();

    document.removeEventListener("keydown", this.keyDownInit);
    document.removeEventListener("keyup", this.keyUpInit);
    window.removeEventListener("keydown", this.keyDownInitWindow);
    window.removeEventListener("dblclick", this.addZoomCenter, false);
    window.removeEventListener("resize", this.onWindowResize, false);
    if (this.active === 0) {
      document.removeEventListener(
        "mousemove",
        this.detectMeasurementIntersection,
        false
      );
    } else if (this.active === 1) {
      document.removeEventListener("click", this.addMarker, false);
      document.removeEventListener(
        "mousemove",
        this.detectModelIntersection,
        false
      );
      document.removeEventListener("keydown", this.deleteSelectedPanel, false);
    } else {
      document.removeEventListener("click", this.addPoint, false);
      window.removeEventListener("mousemove", this.stickMousePointerToDot);
    }
    this.closeEditMode();
    this.disablePointDragMode();
    this.dragControls.removeEventListener("drag", this.dragMarker);
    this.dragControls.removeEventListener("dragstart", this.dragMarkerStart);
    this.dragControls.removeEventListener("dragend", this.dragMarkerEnd);
    next();
  },
  methods: {
    ...mapActions(["getCustomPanels", "setNewCustomPanels"]),
    ...Object.fromEntries(
      [
        ...Object.entries(commonMethods),
        ...Object.entries(authMethods),
        ...Object.entries(navigationMethods),
        ...Object.entries(cameraMethods),
        ...Object.entries(measurementMethods),
        ...Object.entries(areaMethods),
        ...Object.entries(restrictedAreaMethods),
        ...Object.entries(undoRedoMethods),
        ...Object.entries(panelMethods),
        ...Object.entries(areaMeasurementMethods),
      ].map(([key, value]) => [
        key,
        function (...args) {
          return value.apply(this, args);
        },
      ])
    ),
    updateActive(active) {
      this.toggleActive(active);
    },

    distanceMeasurementActivate() {
      this.$refs.mainSidebaRef.handleComponentCreation("distance");
    },
    deselectSidebarDistances() {
      this.$refs.mainSidebaRef.deselectSidebarDistances();
    },
    deselectSidebarArea() {
      this.$refs.mainSidebaRef.deselectSidebarArea();
    },

    selectMeasurementFromCanvas(indexOfDistance) {
      this.$refs.mainSidebaRef.selectMeasurementFromCanvas(indexOfDistance);
    },
    selectAreaOnSidebar(selectedSolarArea) {
      this.$refs.mainSidebaRef.selectAreaOnSidebar(selectedSolarArea);
    },
    unselectSolarAreaOnSidebar() {
      this.$refs.mainSidebaRef.unselectSolarAreaOnSidebar();
    },
    async starClicked(panel) {
      this.likePanel(panel);
    },

    solarGroupActivate() {
      this.$refs.mainSidebaRef.handleComponentCreation("solar");
    },
    areaMeasurementActivate() {
      this.$refs.mainSidebaRef.handleComponentCreation("area");
    },
    distanceMeasurementActivate() {
      this.$refs.mainSidebaRef.handleComponentCreation("distance");
    },
    unselectDistances() {
      this.hideMeasurementDetails();
      this.isMeasurementSelected = false;
      this.deselectSidebarDistances();
    },
    unselectArea() {
      this.unselectMeasurementArea(this.selectedMeasurementArea);
      this.selectedMeasurementArea = null;
      this.deselectSidebarArea();
    },

    handleNavigateToOldPlacements() {
      let url = new URL(window.location.href);
      url.hostname = url.hostname.replace("beta.", "");
      window.location.href = url.toString();
    },
    starClicked(panel) {
      this.likePanel(panel);
    },
    async resyncDefaultValuesOnUpdate(updatedDefaultValues) {
      this.solarDefaultValues = updatedDefaultValues;
    },
    async likePanel(panel) {
      this.likedPanel = panel;
      try {
        await API.airteam3DViewer.updateLikedPanel({
          panelId: panel.id,
        });
      } catch (error) {
        console.error("Failed to update custom panel:", error);
      }
    },
    async unlikePanel() {
      this.likedPanel = null;
      this.likedPanelId = null;
      try {
        await API.airteam3DViewer.unlikePanel();
      } catch (error) {
        console.error("Failed to update custom panel:", error);
      }
    },
    updateSelectedArea(area) {
      this.updateActive(2);
      this.selectAreaWithoutClick(area);
    },
    updateSelectedDistance(distanceId) {
      if (this.active !== 0 && this.active !== 1 && this.active !== 4) {
        this.toggleActive(0);
      }
      this.showMeasurementFromSidebar(distanceId);
    },
    async shareSolarModel() {
      const payload = {
        language: this.$route.query.lang,
        modelId: this.modelId,
        modelPath: this.modelPath,
        projectId: this.projectId,
        projectFlightDate: this.projectFlightDate,
        projectAddress: this.projectAddress,
        orientationX: parseInt(this.orientationX),
        orientationY: parseInt(this.orientationY),
        orientationZ: parseInt(this.orientationZ),
        logo: this.logo,
        scale: this.scale,
        isBeta: window.location.hostname.includes("beta"), // TODO Remove on release of beta to prod
      };
      const { data } = await API.projects.shareFastFusionSolarModel(payload);

      let copiedLink = window.location.href;
      if (!this.anonymousUser) {
        copiedLink = data.link;
      }

      // safari only allows using the clipboard in response to direct user action.
      // but not in response to api calls.
      // this is a workaround it using setTimeout.
      setTimeout(async () => {
        await navigator.clipboard.writeText(copiedLink).catch((err) => {
          ElMessage.error({
            message: "Error occured while copying solar model sharing link",
            showClose: true,
          });
        });
      }, 0);

      if (
        (isProduction && !TEST_COMPANIES.includes(this.companyId)) ||
        isStaging
      ) {
        event("share_ffsolar_model", {
          event_label: "FFSolar model shared",
          method: "copy",
          project_id: this.projectId,
          customer_type: this.activeUserIsEmployee ? "employee" : "customer",
        });
      }
      ElMessage.success({
        message: this.$t("share.success"),
        showClose: true,
      });
    },
    init() {
      this.container = document.createElement("div");
      this.selectionBox = document.createElement("div");
      this.selectionBox.style.border = "3px solid #1fdb9b";
      this.selectionBox.style.position = "absolute";
      this.selectionBox.style.pointerEvents = "none";

      this.$nextTick(() => {
        this.$refs.airteamViewerDiv.appendChild(this.container);
        this.$refs.airteamViewerDiv.appendChild(this.selectionBox);
        this.canvasElementRef = document.querySelector("canvas");
        document.querySelector("canvas").style.height = "100%";
        document.querySelector("canvas").dataset.sl = "canvas-hq";
      });

      /* Scene */
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0x000000);

      this.ambient = new THREE.AmbientLight(WHITE, 3.0);
      this.scene.add(this.ambient);

      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        powerPreference: "high-performance",
        stencil: true,
      });
      this.renderer.autoClear = false;
      this.currentPixelRatio = window.devicePixelRatio;
      this.renderer.setPixelRatio(Math.min(this.currentPixelRatio, 2));
      this.renderer.setSize(
        window.innerWidth * this.getViewerWidth,
        window.innerHeight
      );
      this.renderer.setClearColor(new THREE.Color("hsl(0, 0%, 10%)"));
      this.renderer.domElement.style.cursor = "grab";

      this.renderer.toneMapping = THREE.NoToneMapping;
      this.renderer.outputEncoding = THREE.sRGBEncoding;

      this.container.appendChild(this.renderer.domElement);

      /* Label Renderer */
      this.labelRenderer = new CSS2DRenderer();
      this.labelRenderer.setSize(
        window.innerWidth * this.getViewerWidth,
        window.innerHeight
      );
      this.labelRenderer.domElement.style.position = "absolute";
      this.labelRenderer.domElement.style.top = "0px";
      this.labelRenderer.domElement.style.pointerEvents = "none";
      this.container.appendChild(this.labelRenderer.domElement);

      // arrow
      this.arrowScene = new THREE.Scene();
      const light = new THREE.AmbientLight(WHITE, 3.0);
      this.arrowScene.add(light);

      this.arrowRenderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
      });
      this.arrowRenderer.setSize(50, 50);
      this.$nextTick(() => {
        const arrowContainer = document.getElementById("arrow-container");
        arrowContainer.style.borderRadius = "50%";
        arrowContainer.appendChild(this.arrowRenderer.domElement);
        document.querySelector("canvas").style.height = "100%";
      });

      this.markers = [];
      this.additionalMarkers = [];

      this.raycaster = new THREE.Raycaster();
      this.mouse = new THREE.Vector2();
      this.spriteMaterial = new THREE.SpriteMaterial({
        map: new THREE.TextureLoader().load(newMarker),
      });
      this.getDefaultPanelTypes().then((allPanelTypes) => {
        allPanelTypes.forEach((type) => {
          if (type.orientation === VERTICAL) {
            this.verticalPanelTypes.push(type);
          } else {
            this.horizontalPanelTypes.push(type);
          }
        });
        this.loadPanelTextures();
      });

      this.frustum = new THREE.Frustum();

      this.dotGeometry = new THREE.SphereGeometry(0.1, 32, 32);
      this.middotGeometry = new THREE.SphereGeometry(0.075, 32, 32);

      /* Events */
      window.addEventListener("resize", this.onWindowResize, false);

      // Initialize stats.js
      if (this.activeUserIsEmployee) {
        this.stats = new Stats();
        this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
        document.body.appendChild(this.stats.dom);

        // Style the stats panel to position it on the right side
        this.stats.dom.style.position = "fixed";
        this.stats.dom.style.right = "15%";
        this.stats.dom.style.left = "auto";
        this.stats.dom.style.top = "1rem";
      }
    },
    initializeCamera() {
      /* Camera */
      this.camera = new THREE.PerspectiveCamera(
        50,
        (window.innerWidth * this.getViewerWidth) / window.innerHeight,
        0.01,
        1000
      );

      const zoomOutFactor = this.calculateModelHeight() * 2;
      this.camera.position.set(0, zoomOutFactor, 0);
      this.camera.lookAt(this.modelObject.position);

      this.cameraControls = new CameraControls(
        this.camera,
        this.renderer.domElement
      );

      this.cameraControls.minDistance = 2;
      this.cameraControls.maxDistance = zoomOutFactor * (3 / 2);

      this.cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
      this.cameraControls.mouseButtons.middle = CameraControls.ACTION.ROTATE;
      this.cameraControls.mouseButtons.right = CameraControls.ACTION.ROTATE;
    },
    initializeNorthArrowCamera() {
      this.arrowCamera = new THREE.PerspectiveCamera(
        40,
        (window.innerWidth * this.getViewerWidth) / window.innerHeight,
        0.01,
        1000
      );
      this.arrowCamera.position.set(0, 5, 0);
      this.arrowCamera.lookAt(this.arrowHelper.position);
    },
    renderScene() {
      this.initializeCamera();
      this.dragControls = new DragControls(
        [],
        this.camera,
        this.renderer.domElement
      );

      document.addEventListener("keydown", this.keyDownInit);

      document.addEventListener("keyup", this.keyUpInit);

      document.addEventListener("keydown", this.escClicked, false);
      document.addEventListener("keydown", this.hotKeyClicked, false);

      window.addEventListener("keydown", this.keyDownInitWindow);

      window.addEventListener("dblclick", this.addZoomCenter, false);

      window.addEventListener("wheel", this.hideAuxiliaryValuesOnZoom, false);

      this.animate();
    },
    onProgress(event) {
      const progress = (event.loaded / event.total).toFixed(0);
      this.loadingProgress = progress;
    },
    async showModel(modelName, baseUrl) {
      const uuid = crypto.randomUUID();
      const loader = new GLTFLoader();
      loader.setPath(baseUrl);
      loader.load(
        modelName + `?cache=${uuid}`,
        async (gltf) => {
          this.modelObject = gltf.scene;
          this.exportedManually =
            this.modelObject.children[0].userData?.exported_manually;
          if (this.sample) this.fixSampleModelRotation();
          this.fixModelRotation();
          if (this.exportedManually && !this.hasCalibraton) {
            this.orientationX = 90;
          }
          this.orientationUpdated();
          this.scalingUpdated(this.scale, true);
          this.renderScene();
          this.scene.add(gltf.scene);
          if (!this.sample) {
            await this.loadPanelTextures();
            await this.displayExistingModelObjects();
            this.objectIsloaded = true;
            if (!this.anonymousUser) {
              await Promise.all([
                this.getUserDefaultSolarValues(),
                this.getUserLikedPanel(),
              ]);
            }
          }
          this.toggleActive(0);
          this.loading = false;
          this.onWindowResize();
        },
        this.onProgress
      );
    },
    calculateModelHeight() {
      let modelHeight = 50;

      this.modelObject.traverse((child) => {
        if (child.isMesh) {
          const geometry = child.geometry;

          geometry.computeBoundingBox();
          const boundingBox = geometry.boundingBox;
          const minY = boundingBox.min.y;
          const maxY = boundingBox.max.y;

          const height = maxY - minY;

          if (height) modelHeight = height;
        }
      });
      return modelHeight;
    },
    onWindowResize() {
      this.camera.aspect =
        (window.innerWidth * this.getViewerWidth) / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(
        window.innerWidth * this.getViewerWidth,
        window.innerHeight
      );
      this.labelRenderer.setSize(
        window.innerWidth * this.getViewerWidth,
        window.innerHeight
      );
    },
    animate() {
      if (this.stats) {
        this.stats.begin(); // Start measuring
      }

      requestAnimationFrame(this.animate);
      const delta = this.clock.getDelta();
      this.cameraControls.update(delta);
      this.updateNorthArrow();
      this.updateAllReactivePoints();
      this.updateAllInstancedMeshPoints();
      this.render();
      H.snapshot(this.canvasElementRef);

      if (this.stats) {
        this.stats.end(); // End measuring
      }
    },
    render() {
      this.labelRenderer.render(this.scene, this.camera);
      this.renderer.render(this.scene, this.camera);
      this.arrowRenderer.render(this.arrowScene, this.arrowCamera);
    },
    async showBridge(lightIntensity, modelName, baseUrl) {
      if (!this.sample)
        baseUrl = baseUrl[baseUrl.length - 1] === "/" ? baseUrl : baseUrl + "/";
      await this.showModel(
        this.sample ? "sample_model.glb" : modelName,
        this.sample ? "/assets/model/" : baseUrl
      );
      this.showCamera();
      await this.loadNorthArrow();
    },
    toggleActive(value) {
      this.updateMeasurementLabelCursors(value);

      if (value != this.active) {
        if (this.isMeasurementSelected) {
          this.unselectDistances();
        }
        if (this.selectedMeasurementArea) {
          this.unselectArea();
        }
      }

      if (value !== 1 && value !== 4) this.measurementModeActive = false;
      if (value === 1 || value === 4) {
        this.measurementModeActive = true;
        this.measurementMenuExpanded = true;
      }
      if (value === 1 || value === 2 || value === 4) {
        this.$refs.mainSidebaRef.handleModeChange(value);
      }

      this.toggleView(value);
    },

    escClicked(event) {
      const key = event.key;
      if (key === "Escape") {
        // Check for measurement deselection when active is 0 or 1
        if (
          (this.active === 0 || this.active === 1) &&
          this.isMeasurementSelected
        ) {
          this.unselectDistances();
        }
        // Check for measurement area deselection when active is 0 or 4
        else if (
          (this.active === 0 || this.active === 4) &&
          this.selectedMeasurementArea
        ) {
          this.unselectArea();
        }
        // Handle other cases
        else if (this.active === 1) {
          this.removeUnfinishedMeasurements();
        } else if (this.active === 2) {
          this.removeUnfinshedArea();
          this.unselectSolarArea();
        } else if (this.active === 3) {
          this.disableIndividualPlacementMode();
          if (
            this.selectedArea &&
            this.selectedArea.restrictedAreas.length > 0
          ) {
            this.showRestrictedAreaDetails(this.selectedArea);
          }
        } else if (this.active === 4) {
          this.removeUnfinishedAreaMeasurements();
        } else if (this.active === 6) {
          this.disableRestrictedAreaMode();
        }
      }
    },
    hotKeyClicked(event) {
      const key = event.key;
      if (key === "s") {
        if (this.active === 2 && this.selectedArea) {
          this.toggleActive(6);
        } else if (this.active === 6) {
          this.disableRestrictedAreaMode();
        }
      } else if (key === "e") {
        if (this.active === 2 && this.selectedArea) {
          this.enableIndividualPlacementMode("VERTICAL");
        } else if (this.active === 3) {
          this.disableIndividualPlacementMode();
        }
      } else if (key === "w") {
        if (this.active === 3) {
          this.toggleIndividualPanelOrientation();
        }
      } else if (key === "d") {
        this.toggleActive(1);
      } else if (key === "f") {
        this.toggleActive(4);
      }
    },
    toggleView(view) {
      this.cleanNavigationSetup();

      this.modes.forEach((mode) => {
        if (mode.enabled && view !== 3 && view !== 6) {
          mode.disableFunction.call(this, view);
        }
      });

      if (this.active === 3 || this.active === 6) {
        this.modes.find((mode) => mode.value === this.active).disableFunction();
      }

      this.active = view;

      const currentMode = this.modes.find((mode) => mode.value === view);

      if (currentMode) {
        currentMode.enableFunction.call(this);
      }
      if (view === null || view === 0) {
        this.modes.find((mode) => mode.value === 2).disableFunction();
      }
    },
    dragMouseDown(event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop -
        this.positions.movementY +
        "px";
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft -
        this.positions.movementX +
        "px";
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
    toggleSidebar(event) {
      this.sidebarOpen = !this.sidebarOpen;
      this.onWindowResize();
      event.stopPropagation();
    },
    backToProjectPage() {
      window.location.href = `${window.runtimeConfig?.AFP_URL}project/${this.projectId}`;
    },
    backtoProjects() {
      window.location.href = `${window.runtimeConfig?.AFP_URL}project/list`;
    },
    switchToNira() {
      if (
        (isProduction && !TEST_COMPANIES.includes(this.companyId)) ||
        isStaging
      ) {
        event("switch_to_nira", {
          page_title: "AirteamViewer",
          page_location: window.location.href,
          page_path: window.location.pathname,
          project_id: this.projectId,
          user: this.activeUser?.email,
          date: Date.now(),
          customer_type: this.activeUserIsEmployee ? "employee" : "customer",
        });
      }
      window.location.href = `${window.runtimeConfig?.AFP_URL}project/${this.projectId}/fast-fusion-3d-viewer/${this.niraId}/false`;
    },
    takeScreenshot() {
      if (this.active !== 0) return;
      // take screenshot
      let img = new Image();
      this.renderer.render(this.scene, this.camera);
      img.src = this.renderer.domElement.toDataURL();
      let msg = this.$t("screenshot.success");
      if (this.screenshot) msg = this.$t("screenshot.overwrite");
      this.screenshot = img;
      ElMessage.success({
        message: msg,
        showClose: true,
        duration: "2500",
      });
    },
    printPage() {
      if (
        (isProduction && !TEST_COMPANIES.includes(this.companyId)) ||
        isStaging
      ) {
        event("generate_ffsolar_report", {
          page_title: "AirteamViewer",
          page_location: window.location.href,
          page_path: window.location.pathname,
          project_id: this.projectId,
          isCustomer: !this.activeUserisEmployee,
          customer_type: this.activeUserIsEmployee ? "employee" : "customer",
        });
      }
      let sizeToPanelNumberUl =
        this.$i18n.locale === "de"
          ? "Anzahl der Solarmodule pro Größe: <ul>"
          : "Amount of solar panels per size: <ul>";
      let sizeToPanelSurfaceUl =
        this.$i18n.locale === "de"
          ? "Fläche pro Größe: <ul>"
          : "Surface per size: <ul>";
      const [sizeToPanelNumber, sizeToPanelSurface] = this.panelsDataPerSize;

      let totalPitchAngle = 0;
      let totalSurfaceArea = 0;
      for (let area of this.areas.filter((area) => area.closed)) {
        const surfaceArea = this.calculateSurfaceArea(area);
        totalPitchAngle += surfaceArea * area.angle;
        totalSurfaceArea += Number(surfaceArea);
      }
      const averagePitchAngle = (totalPitchAngle / totalSurfaceArea).toFixed(2);

      Object.entries(sizeToPanelNumber).forEach(([size, panelNumber]) => {
        sizeToPanelNumberUl += `<li>${size}: ${panelNumber}</li>`;
      });
      sizeToPanelNumberUl += "</ul>";

      Object.entries(sizeToPanelSurface).forEach(([size, panelSurface]) => {
        sizeToPanelSurfaceUl += `<li>${size}: ${panelSurface.toFixed(
          2
        )}m²</li>`;
      });
      sizeToPanelSurfaceUl += "</ul>";

      const project = this.$i18n.locale === "de" ? "Projekt" : "Project";
      const numberOfSolarPanels =
        this.$i18n.locale === "de"
          ? "Anzahl der Solarmodule"
          : "Number of solar panels";
      const totalPanelSurface =
        this.$i18n.locale === "de"
          ? "Gesamtfläche der Solarmodule"
          : "Total solar panel surface";
      const averagePitchAngleTitle =
        this.$i18n.locale === "de"
          ? "Durchschnittlicher Neigungswinkel des Bereichs"
          : "Average area pitch angle";

      const pitchAngleTitle = (id) =>
        this.$i18n.locale === "de"
          ? `Bereich ${id} Steigungswinkel`
          : `area ${id} pitch angle`;

      const pitchAnglePerArea =
        this.$i18n.locale === "de"
          ? "Neigungswinkel pro Fläche"
          : "Pitch angle per area";

      const h4 = this.sample
        ? "Sample Project"
        : `${project} Id: ${this.model.project.pk}`;
      let printData = `<h4>${h4}</h4><p>${numberOfSolarPanels}: ${this.totalNumberOfPanels}</p><p>${totalPanelSurface}: ${this.totalPanelsArea}m²</p><p>${averagePitchAngleTitle}: ${averagePitchAngle}°</p>`;
      printData += sizeToPanelNumberUl;
      printData += sizeToPanelSurfaceUl;

      const closedAreas = this.areas.filter((area) => area.closed);
      if (closedAreas.length > 0)
        printData += `<p>${pitchAnglePerArea}:</p><ul>`;
      for (let i = 0; i < closedAreas.length; i++) {
        printData += `<li>${pitchAngleTitle(i + 1)}: ${
          closedAreas[i].angle
        }°</li>`;
      }
      if (closedAreas.length > 0) printData += "</ul>`";
      if (this.screenshot)
        printData += `<img src=${this.screenshot.src} width="500"/>`;
      this.$htmlToPaper(printData);
    },
    undo() {
      if (this.undoStack.length === 0) return;
      const lastAction = this.undoStack.pop();
      switch (lastAction.action) {
        case "ADD_MEASUREMENT_POINT":
          this.undoAddMeasurementPoint(lastAction.measurement);
          break;
        case "ADD_POINT":
          this.undoAddPoint(
            lastAction.area,
            lastAction.point,
            lastAction.line,
            lastAction.midPoint,
            lastAction.oldTempLine,
            lastAction.oldFirstPoint,
            lastAction.newTempLine,
            lastAction.oldTempLabel,
            lastAction.newTempLabel,
            lastAction.fixedLine
          );
          break;
        case "MOVE_POINT":
          this.undoMovePoint(
            lastAction.areaType,
            lastAction.area,
            lastAction.index,
            lastAction.position
          );
          break;
        case "MOVE_MEASUREMENT_POINT":
          this.undoMoveMeasurementPoint(
            lastAction.measurement,
            lastAction.point
          );
          break;
        case "MOVE_MID_POINT":
          this.undoMoveMidPoint(
            lastAction.area,
            lastAction.index,
            lastAction.isMeasurement
          );
          break;
        case "DELETE_AREA":
          this.undoDeleteArea(lastAction.area);
          break;
        case "DELETE_MEASUREMENT_AREA":
          this.undoDeleteMeasurementArea(lastAction.area);
          break;
        case "DELETE_MEASUREMENT":
          this.undoDeleteMeasurement(lastAction.measurement);
          break;
        case "ADD_PANEL":
          this.undoAddPanel(lastAction.panel, lastAction.area);
          break;
        case "BULK_ADD_PANELS":
          this.undoBulkAddPanels(lastAction.panels, lastAction.area);
          break;
        case "MOVE_PANELS":
          this.undoMovePanels(lastAction.panels, lastAction.area);
          break;
        case "DELETE_PANELS":
          this.undoDeletePanels(lastAction.panels, lastAction.area);
          break;
        case "MERGE_POINT":
          this.undoMergePoint(
            lastAction.area,
            lastAction.points,
            lastAction.isMeasurement
          );
          break;
        default:
          null;
      }
    },
    redo() {
      if (this.redoStack.length === 0) return;
      const lastAction = this.redoStack.pop();
      switch (lastAction.action) {
        case "ADD_MEASUREMENT_POINT":
          this.redoAddMeasurementPoint(lastAction.measurement);
          break;
        case "ADD_POINT":
          this.redoAddPoint(
            lastAction.area,
            lastAction.point,
            lastAction.line,
            lastAction.midPoint,
            lastAction.oldTempLine,
            lastAction.oldFirstPoint,
            lastAction.newTempLine,
            lastAction.oldTempLabel,
            lastAction.newTempLabel,
            lastAction.fixedLine
          );
          break;
        case "MOVE_POINT":
          this.redoMovePoint(
            lastAction.areaType,
            lastAction.area,
            lastAction.index,
            lastAction.position
          );
          break;
        case "MOVE_MEASUREMENT_POINT":
          this.redoMoveMeasurementPoint(
            lastAction.measurement,
            lastAction.point
          );
          break;
        case "MOVE_MID_POINT":
          this.redoMoveMidPoint(
            lastAction.area,
            lastAction.index,
            lastAction.points,
            lastAction.isMeasurement
          );
          break;
        case "DELETE_AREA":
          this.redoDeleteArea(lastAction.area);
          break;
        case "DELETE_MEASUREMENT_AREA":
          this.redoDeleteMeasurementArea(lastAction.area);
          break;
        case "DELETE_MEASUREMENT":
          this.redoDeleteMeasurement(lastAction.measurement);
          break;
        case "ADD_PANEL":
          this.redoAddPanel(lastAction.panel, lastAction.area);
          break;
        case "BULK_ADD_PANELS":
          this.redoBulkAddPanels(lastAction.panels, lastAction.area);
          break;
        case "MOVE_PANELS":
          this.redoMovePanels(lastAction.panels, lastAction.area);
          break;
        case "DELETE_PANELS":
          this.redoDeletePanels(lastAction.panels, lastAction.area);
          break;
        case "MERGE_POINT":
          this.redoMergePoint(
            lastAction.area,
            lastAction.points,
            lastAction.isMeasurement
          );
          break;
        default:
          null;
      }
    },
    resetRedoStack() {
      this.redoStack = [];
    },
    resetUndoStack() {
      this.undoStack = [];
    },
    fixModelRotation() {
      // this.modelObject.children[0].rotation.x = Math.PI * (-90 / 180);
      // this.orientationX = -90;
    },
    fixSampleModelRotation() {
      this.modelObject.children[0].rotation.z = Math.PI * (60 / 180);
    },
    orientationUpdated() {
      this.modelObject.children[0].rotation.x =
        Math.PI * (this.orientationX / 180);
      this.modelObject.children[0].rotation.y =
        Math.PI * (this.orientationY / 180);
      this.modelObject.children[0].rotation.z =
        Math.PI * (this.orientationZ / 180);
    },
    async scalingUpdated(scalingValue, isInitialization) {
      this.removeMeasurements();
      if (scalingValue) {
        this.modelObject?.scale.set(scalingValue, scalingValue, scalingValue);
        if (!isInitialization) {
          await API.projects.patchProject(this.projectId, {
            solarModelScale: scalingValue,
          });
          this.currentProject.scale = scalingValue;
        }
      }
    },
    async saveOrientation() {
      let orientationData = {
        x: this.orientationX,
        y: this.orientationY,
        z: this.orientationZ,
      };
      const orientation = JSON.stringify(orientationData);
      await API.projects.patchProject(this.projectId, {
        solarModelOrientation: orientation,
      });

      this.currentProject.orientation = orientation;
    },
    trackPageView() {
      const sessionEndTime = new Date();
      const sessionDurationSeconds = Math.round(
        (sessionEndTime - this.sessionStartTime) / 1000
      );
      if (
        (isProduction && !TEST_COMPANIES.includes(this.companyId)) ||
        isStaging
      ) {
        event("fast_fusion_solar_session", {
          page_title: "AirteamViewer",
          page_location: window.location.href,
          page_path: window.location.pathname,
          project_id: this.projectId,
          user: this.activeUser?.email,
          date: Date.now(),
          session_duration: sessionDurationSeconds,
          customer_type: this.activeUserIsEmployee ? "employee" : "customer",
        });
      }
    },
    detectModelIntersection(event) {
      let intersects;
      if (this.active === 4) {
        intersects =
          this.updatePreliminaryPointPositionForMeasurementArea(event);
      } else if (this.active === 2) {
        intersects = this.updatePreliminaryPointPositionForArea(event);
      } else if (this.active === 6) {
        intersects =
          this.updatePreliminaryPointPositionForRestrictedArea(event);
      } else {
        intersects = this.updatePreliminaryPointPosition(event);
      }

      if (intersects?.length > 0) {
        this.mouseOverModel = true;
        if (this.mouseOverPoint) {
          this.changeCursorToPointer();
        } else if (!this.inMagenticField) {
          this.changeCursorToCrosshair();
        }
      } else {
        this.mouseOverModel = false;
        this.restoreDefaultCursor();
      }
    },
    displayExistingObject(element) {
      if (element.type === "MEASUREMENT") {
        this.existingMeasurementsIds.push(element.id);
        element?.position.forEach((point) => {
          this.displayMarker(element, point);
        });
      } else if (["AREA", "SOLAR_GROUP"].includes(element.type)) {
        this.existingAreasIds.push(element.id);
        this.displayAreaPoint(element, false);
        this.areas[this.areas.length - 1].id = element.id;
        this.areas[this.areas.length - 1].show = element.show;
        this.areas[this.areas.length - 1].type = element.type;

        if (element.type === "SOLAR_GROUP") {
          this.useStoredSolarValues(this.areas[this.areas.length - 1], element);
        }

        this.drawPlane(this.areas[this.areas.length - 1]?.points, false);

        this.areas[this.areas.length - 1].restrictedAreas =
          element.restricted_areas;

        this.selectedArea = this.areas[this.areas.length - 1];
        this.processRestrictedAreas(this.areas[this.areas.length - 1]);

        this.displayIndividualPanels(this.areas[this.areas.length - 1]);
      } else if (element.type === "PANEL") {
        this.displayPanel(element);
      } else if (element.type === "AREA_MEASUREMENT") {
        this.displayAreaPoint(element, true);
        this.measurementAreas[this.measurementAreas.length - 1].id = element.id;
        this.measurementAreas[this.measurementAreas.length - 1].show =
          element.show;

        this.drawMeasurementAreaPlane(
          this.measurementAreas[this.measurementAreas.length - 1]?.points,
          false
        );
        if (
          this.measurementAreas[this.measurementAreas.length - 1].show === false
        ) {
          this.hideSingleMeasurementArea(
            this.measurementAreas[this.measurementAreas.length - 1]
          );
        }
      }
    },
    async displayExistingModelObjects() {
      const api = await API.airteam3DViewer.getFfSolarObjectsByProjectId(
        this.projectId
      );
      const elements = api.data;
      elements
        .sort((a, b) => {
          const typeOrder = {
            AREA: 1,
            AREA_MEASUREMENT: 2,
            MEASUREMENT: 3,
            PANEL: 4,
          };

          return typeOrder[a.type] - typeOrder[b.type];
        })
        .forEach((element) => {
          this.displayExistingObject(element);
        });
      this.hideAreas(false);
      this.setPanelsOpaque();
    },
    showSmallMeasurementMenu() {
      if (this.active === 1 || this.active === 4) {
        this.measurementMenuExpanded = false;
      }
    },
    async removeCustomPanel(event, panelToRemove) {
      const customPanels = this.customPanels.map((panel) => {
        const updatedPanel = { ...panel };
        if (panel.name === panelToRemove.name) {
          updatedPanel.show = false;
        }
        return updatedPanel;
      });
      this.setNewCustomPanels(customPanels);
      try {
        await API.airteam3DViewer.updateCustomPanel({
          id: panelToRemove.id,
          show: false,
        });
      } catch (error) {
        console.error("Failed to update custom panel:", error);
      }
      event.stopPropagation();
    },
    async loadNorthArrow() {
      const loader = new GLTFLoader();
      loader.load("/assets/model/northarrow_v2.glb", (gltf) => {
        this.arrowHelper = gltf.scene;
        this.arrowScene.add(this.arrowHelper);
        this.initializeNorthArrowCamera();
      });
    },
    updateNorthArrow() {
      this.arrowCamera.quaternion.copy(this.camera.quaternion);

      const forward = new THREE.Vector3(0, 0, -1);
      forward.applyQuaternion(this.camera.quaternion);

      const distance = 5;

      const newPosition = new THREE.Vector3()
        .copy(this.arrowHelper.position)
        .add(forward.multiplyScalar(-distance));

      this.arrowCamera.position.copy(newPosition);

      this.arrowCamera.lookAt(this.arrowHelper.position);
    },
    handleOrbitClick() {
      if (this.navigationMenu === 0) {
        // If already in orbit mode, switch back to select mode
        this.toggleActive(0);
        this.navigationMenu = null;
      } else {
        // Otherwise switch to orbit mode
        this.toggleNavigation(0);
      }
    },
    handlePanClick() {
      if (this.navigationMenu === 1) {
        // If already in pan mode, switch back to select mode
        this.toggleActive(0);
        this.navigationMenu = null;
      } else {
        // Otherwise switch to pan mode
        this.toggleNavigation(1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/scss/_typography" as typography;
@use "@/scss/_colors" as colors;

.project-panel {
  &__summary {
    @include typography.getFont($font-name: subtitle1);
    color: white;
  }
  &__field {
    @include typography.getFont($font-name: subtitle3);
    font-size: 12px;
    display: flex;
    justify-content: space-between;

    &--label {
      color: white;
      font-weight: 400;
    }

    &--value {
      color: white;
      font-weight: 200;
    }
  }
  &__settings {
    justify-content: space-between;
  }
  &__solar-card {
    border-radius: 8px;
    border: 0;
    background: colors.$GREB50;

    &-header {
      border-radius: 8px;
    }

    &-content {
      background: colors.$GREB50;
    }
  }
}

.breadcrumbs {
  &__container {
    background-color: #333537;
    color: white;
  }

  &__last {
    font-weight: bold;
    color: white !important;
  }
}

.new-sidebar-layout {
  background: #27292c;
  color: white;
  height: 100vh;

  p {
    color: white;
  }
  span {
    color: white;
  }
}
.sidebar-bottom {
  max-height: 44rem;
  overflow-y: scroll;
  scrollbar-width: none;
}

hr.project-panel__line {
  margin-left: -24px;
  margin-right: -24px;
}

.close-sidebar {
  margin-right: -35px;

  div {
    background-color: #fff;
    z-index: 500;
    position: relative;
    border: solid 1px #e1e1e1;
    border-radius: 10px;
  }
}

.open-sidebar {
  margin-top: 20px;
  position: absolute;

  div {
    background-color: #fff;
    z-index: 500;
    position: relative;
    border: solid 1px #e1e1e1;
    border-radius: 10px;
  }
}

.panels__toolbar {
  background: colors.$GREB50;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px 0px;
  z-index: 15 !important;

  &--small {
    padding: 5px;
  }

  &--area {
    padding-right: 0;
  }

  &-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    flex-wrap: nowrap;

    &--small {
      height: 35px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 5px;
      background: #27292c;
    }

    &--area {
      margin-left: 0;
    }
  }
  &-trash {
    margin-left: 15px;
    margin-top: 5px;
  }
  &-container {
    max-height: 50vh;
    overflow: scroll;
    margin: 0;
  }
  &-custom-button {
    width: calc(100% - 24px);
  }
  &-submenu {
    &__title {
      @include typography.getFont($font-name: subtitle1);
      color: black;
    }
    &__header {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.05em;

      &--small {
        font-size: 13px;
      }
    }
    &__subheader {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.05em;
    }
    &__button {
      margin-left: 0;
      display: flex;
      justify-content: center;

      :deep(button) {
        width: 142px;
      }
    }
  }
}

.main-menu {
  background: #27292c;

  &--small {
    background: #3b3c3d;
  }
}

.panel--selected {
  background: colors.$HONE200;
}

.position__right {
  position: absolute;
  right: 0;
}

.mt-auto {
  margin-top: auto;
}

.airteam-viewer {
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  margin: 0;
  padding: 0px;
  background: hsl(0, 0%, 30%);

  :deep(canvas) {
    width: 100% !important;
  }
}

.col-p5 {
  width: 2%;
  text-align: center;
}

.col-115 {
  position: relative;
  width: 98%;
}

.position4,
.position5,
.position6,
.position55 {
  position: absolute;
  color: #fff;
  z-index: 15 !important;
}

.add-panels-menu {
  width: 240px;
}

.position4 {
  position: fixed;
  bottom: 14px;
  margin-left: 24px;
  text-align: left;
}

.position5 {
  top: 35%;
  transform: translateY(-50%);
  text-align: left;
}

.position55 {
  top: 30%;
  left: 60px;
  transform: translateY(-50%);
  text-align: left;
}

.position6 {
  top: 50%;
  transform: translateY(-50%);
  margin-left: 6.7rem;
  text-align: left;
}

.help-button {
  margin: -30px -30px -45px -30px !important;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  width: 80px;
}

.airteam-logo {
  margin-top: -12px;
}

a {
  color: #1fdb9b;
}

.button {
  border: none;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 2px;
  cursor: pointer;
  outline: none;
}

.add-panels {
  width: 100%;
}

.report-button {
  width: calc(100% - 48px);
  bottom: 40px;
  position: absolute;
}

.add-panels-text {
  margin: 4px 0px;
  color: #8c9fa7;
  letter-spacing: 1.5px;
  font-size: 8px;
}

.dark {
  background-color: #0d5e43;
  color: white;
}

.Cclay {
  background-color: #c4724c;
  color: white;
}

.Cblue {
  background-color: #1557b5;
  color: white;
}

.Cgreen {
  background-color: #1af4aa;
  color: black;
  border-radius: 25px;
}

.Cgrey {
  background-color: #aba9aa;
  color: black;
}

.Cred {
  background-color: #711103;
  color: white;
}

.green-dot-cursor {
  cursor: url("@/assets/icons/green-dot.svg"), auto;
}

.panel-text {
  color: #7d7d7d;
  font-size: 10px;

  &--active {
    color: #099483;
  }
}

#draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
  cursor: grab;
}

.close-row {
  margin-top: -15px;
}

.close-menu {
  cursor: pointer;
}

.move-menu {
  position: absolute;
  left: 0;
  margin-left: -40px;
  margin-top: -25px;
}

.position0 {
  position: absolute;
  display: flex;
  top: 1.4em;
  left: 1em;
  text-align: right;
  margin-left: 50px;
}

.undo-redo {
  background: rgba(59, 60, 61, 1);
  border-radius: 8px;
  z-index: 15 !important;
  display: inline-block;
  width: 50px;
  height: 45px;
  text-align: center;
  border: 1px solid rgba(39, 41, 44, 1);
}

.screenshot-bg {
  background-color: rgba(59, 60, 61, 1);
  padding: 0 20px;
  text-align: center;
}

.position1 {
  position: absolute;
  color: colors.$EMER500;
  right: 0;
  border-bottom-left-radius: 8px;
  text-align: right;
  z-index: 150 !important;
}

.position2 {
  position: absolute;
  right: 0;
  top: 60px;
  padding: 0 20px;
  text-align: right;
  z-index: 15 !important;
}

.position9 {
  position: fixed;
  bottom: 0;
  left: 50%;
  background: #27292c;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 15 !important;
}

.position10 {
  color: white;
  position: fixed;
  bottom: 16px;
  right: 16px;
  background-color: rgba(238, 238, 238, 0.1);
  padding: 12px;
  border-radius: 4px;
  font-size: 12px;
}

.position11 {
  position: fixed;
  bottom: 64px;
  right: 16px;
  background-color: rgba(238, 238, 238, 0.1);
  padding: 12px;
  border-radius: 4px;
  font-size: 12px;
}

.cursor-disabled {
  cursor: not-allowed;
  color: colors.$GREY500;
}

.icon {
  cursor: pointer;
}

.height-75 {
  height: 75px;
}

.height-250 {
  height: 250px;
}

.menu-icon {
  padding: 0px;
  margin-bottom: 4px;

  &--small {
    width: 18px;
  }
}

.undo-arrow {
  width: 35px;
  height: 20px;
  position: relative;
  top: 40%;
  transform: translateY(-40%);
}

.orientation-values {
  display: flex;
  flex-direction: row;
}

.ff3d-btn::before {
  border-color: colors.$GREY300 !important;
}

.menu-text {
  font-weight: normal;
}

.menu-text--active {
  color: #23e7a5;
}

.wrapper {
  background: black;
  height: 100vh;
}

.progress-container {
  color: #fafafa;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.progress-hint {
  font-size: 18px;
  font-weight: normal;
}

.thumbnail {
  margin-bottom: 8px;
  width: 500px;
}

#imageContainer {
  z-index: 1000;
  position: absolute;
  display: none;
  pointer-events: none; /* This ensures the container doesn't interfere with mouse events */
}

#zoom-plus,
#zoom-minus {
  position: absolute;
  width: 50px;
  height: 50px;
}

#zoom-plus {
  bottom: 100%; /* Position it above the container's top edge */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Adjust for image's own width */
}

#zoom-minus {
  top: 20px; /* Position it below the container's bottom edge */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Adjust for image's own width */
}

@media only screen and (max-width: 560px) {
  .hide-mobile {
    display: none;
  }

  .position9 {
    left: 10%;
  }

  .position1 {
    padding: 10px 20px;
  }

  .project-panel {
    z-index: 100;
  }

  .close-sidebar {
    margin-right: 0;
  }

  .open-sidebar {
    margin-left: 20px;
  }
}
</style>

<style lang="scss">
@media (max-width: 1600px) {
  .q-item__label {
    font-size: 12px;
  }
  .q-field__control.relative-position.row.no-wrap {
    padding: 0 3px;
    input {
      text-align: end;
    }
  }
  span {
    font-size: 12px;
  }
}

@media (max-width: 1450px) {
  .q-field__control.relative-position.row.no-wrap {
    padding: 0 1px;
    input {
      text-align: end;
    }
  }
}
@media (max-height: 850px) {
  .new-sidebar-layout {
    .sidebar-bottom {
      max-height: 38rem;
    }
  }
}
</style>
