import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { CSS2DObject } from "three/addons/renderers/CSS2DRenderer.js";
import {
  MIDPOINT_COLOR,
  BLACK,
  POINT_COLOR,
  SOLAR_POINT_COLOR,
  RESTRICTED_AREA_COLOR,
  ZOOM_FACTOR,
  RENDERING_ORDER,
} from "../constants";
import snapCursor from "@/assets/model/snap_cursor.svg";
import trashRed from "@/assets/model/trash_red.svg";
import { InstancedMesh2 } from "@three.ez/instanced-mesh";

function hexToRGB(color) {
  const r = ((color >> 16) & 0xff) / 255;
  const g = ((color >> 8) & 0xff) / 255;
  const b = (color & 0xff) / 255;
  return { r, g, b };
}

export const createShaderMaterial = function (
  innerColorHex,
  outerColorHex,
  threshold
) {
  const vertexShader = `
  varying vec3 vViewPosition;
  varying vec3 vNormal;

  void main() {
    vNormal = normalMatrix * normal;
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    vViewPosition = -mvPosition.xyz;
    gl_Position = projectionMatrix * mvPosition;
  }
  `;

  const fragmentShader = `
  uniform vec3 innerColor;
  uniform vec3 outerColor;  
  uniform float rimThreshold;
  varying vec3 vViewPosition;
  varying vec3 vNormal;

  void main() {
    vec3 normal = normalize(vNormal);
    vec3 viewDir = normalize(vViewPosition);
    
    float rim = 1.0 - abs(dot(normal, viewDir));
    
    vec3 color = innerColor;
    float alpha = 1.0;
    
    if (rim > rimThreshold) {
      color = outerColor;
    }
    
    gl_FragColor = vec4(color, alpha);
  }
  `;

  const innerColorRgb = hexToRGB(innerColorHex);
  const innerColor = new THREE.Color(
    innerColorRgb.r,
    innerColorRgb.g,
    innerColorRgb.b
  );

  const shaderMaterial = new THREE.ShaderMaterial({
    vertexShader,
    fragmentShader,
    uniforms: {
      innerColor: { value: innerColor },
      outerColor: { value: new THREE.Color(outerColorHex) },
      rimThreshold: { value: threshold },
    },
    transparent: true,
    opacity: 1.0,
  });

  return shaderMaterial;
};

export const createShaderMaterialForInstancedMesh = function (
  innerColorHex,
  outerColorHex,
  threshold
) {
  const vertexShader = `
  #include <get_from_texture>
  #include <instanced_pars_vertex>

  varying vec3 vViewPosition;
  varying vec3 vNormal;

  void main() {
    #include <instanced_vertex>
    
    vNormal = normalMatrix * normal;
    vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(position, 1.0);
    vViewPosition = -mvPosition.xyz;
    gl_Position = projectionMatrix * mvPosition;
  }
  `;

  const fragmentShader = `
  uniform vec3 innerColor;
  uniform vec3 outerColor;  
  uniform float rimThreshold;  
  varying vec3 vViewPosition;
  varying vec3 vNormal;

  void main() {
    vec3 normal = normalize(vNormal);
    vec3 viewDir = normalize(vViewPosition);
    
    float rim = 1.0 - abs(dot(normal, viewDir));
    
    vec3 color = innerColor;
    float alpha = 1.0;
    
    if (rim > rimThreshold) {
      color = outerColor;
    }
    
    gl_FragColor = vec4(color, alpha);
  }
  `;

  const innerColorRgb = hexToRGB(innerColorHex);
  const innerColor = new THREE.Color(
    innerColorRgb.r,
    innerColorRgb.g,
    innerColorRgb.b
  );

  const shaderMaterial = new THREE.ShaderMaterial({
    vertexShader,
    fragmentShader,
    uniforms: {
      innerColor: { value: innerColor },
      outerColor: { value: new THREE.Color(outerColorHex) },
      rimThreshold: { value: threshold },
    },
    transparent: true,
    opacity: 1.0,
  });

  return shaderMaterial;
};

export const createReactivePoint = function (
  referencePoint,
  color,
  addSnapIcon = false
) {
  const material = this.createShaderMaterial(color, BLACK, 0.25);

  const point = new THREE.Mesh(this.dotGeometry, material);

  point.position.copy(referencePoint);
  point.material.depthTest = false;
  point.renderOrder = RENDERING_ORDER.OUTER_POINT;

  if (addSnapIcon) {
    const icon = this.createSnapIcon();
    point.add(icon);
  }

  return point;
};

export const createSnapIcon = function () {
  const iconHtml = `<img class="snap-icon" src="${snapCursor}" />`;
  const iconElement = document.createElement("div");
  iconElement.innerHTML = iconHtml;
  iconElement.style.visibility = "hidden";

  const iconLabel = new CSS2DObject(iconElement);

  return iconLabel;
};

export const createReactiveMidPoint = function (
  startPoint,
  endPoint,
  color = BLACK
) {
  const position = new THREE.Vector3().lerpVectors(
    startPoint.position,
    endPoint.position,
    0.5
  );

  const material = this.createShaderMaterial(BLACK, color, 0.3);

  const midpoint = new THREE.Mesh(this.middotGeometry, material);

  midpoint.position.copy(position);
  midpoint.material.depthTest = false;
  midpoint.renderOrder = RENDERING_ORDER.OUTER_POINT;

  this.scene.add(midpoint);

  return midpoint;
};

export const createLabelBetweenTwoPoints = function (
  firstPoint,
  secondPoint,
  spacing,
  removable = false,
  line,
  offset = false
) {
  const distance = secondPoint.distanceTo(firstPoint);
  let div = document.createElement("div");
  div.className = "measurementLabel";
  div.distance = distance;

  if (removable) {
    let span = document.createElement("span");
    span.textContent = `${distance.toFixed(2)}`;

    let img = document.createElement("img");
    img.src = trashRed;
    img.className = "q-ml-xs cursor-pointer";
    img.style.width = "15px";
    img.style.display = "none";

    div.appendChild(span);
    div.appendChild(img);

    div.style.cursor = "pointer";
    div.addEventListener("pointerdown", () => {
      this.showMeasurement(line.id);
    });

    img.addEventListener("pointerdown", () => {
      this.deleteMeasurement(line.id);
    });
  } else {
    div.textContent = `${distance.toFixed(2)}`;
  }
  let label = new CSS2DObject(div);
  label.position.lerpVectors(secondPoint, firstPoint, spacing);

  if (offset) {
    const direction = new THREE.Vector3()
      .subVectors(secondPoint, firstPoint)
      .normalize();

    const up = new THREE.Vector3(0, -1, 0);
    const perpendicular = new THREE.Vector3()
      .crossVectors(direction, up)
      .normalize();

    label.position.addScaledVector(perpendicular, 0.5);
  }

  label.layers.set(0);
  label.renderOrder =
    RENDERING_ORDER.MEASUREMENT_LABEL + this.annotationRenderOrder;
  this.annotationRenderOrder += 1;

  return label;
};

export const updateLabelBetweenTwoPoints = function (
  label,
  firstPoint,
  secondPoint,
  spacing,
  offset = false,
  removable = false
) {
  const distance = secondPoint.distanceTo(firstPoint);
  const div = label.element;
  div.distance = distance;
  if (removable) {
    const span = document.createElement("span");
    span.textContent = `${distance.toFixed(2)}`;
    div.replaceChild(span, div.firstChild);
    span.addEventListener("pointerdown", () => {
      this.showMeasurementDetails(
        this.measurements[this.measurements.length - 1],
        true
      );
      this.measurementDetailsPersistent = true;
      this.measurementLabelClicked = true;
    });
  } else {
    div.textContent = `${distance.toFixed(2)}`;
  }
  label.position.lerpVectors(secondPoint, firstPoint, spacing);

  if (offset) {
    const direction = new THREE.Vector3()
      .subVectors(secondPoint, firstPoint)
      .normalize();

    const up = new THREE.Vector3(0, -1, 0);
    const perpendicular = new THREE.Vector3()
      .crossVectors(direction, up)
      .normalize();

    label.position.addScaledVector(perpendicular, 0.5);
  }
  return distance;
};

export const createReactiveThickLine = function (
  points,
  thickness,
  dashed = false,
  transparent = false,
  color = MIDPOINT_COLOR
) {
  const flatPoints = [];

  for (const vector of points) {
    flatPoints.push(vector.x, vector.y, vector.z);
  }
  let geometry = new LineGeometry();
  geometry.setPositions(flatPoints);

  const lineMaterial = new LineMaterial({
    color: color,
    linewidth: thickness,
    dashed,
    dashSize: 0.4,
    gapSize: 0.3,
    resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
    transparent: true,
  });
  if (dashed) lineMaterial.defines.USE_DASH = "";
  if (transparent) lineMaterial.uniforms.opacity.value = 0.4;

  let line = new Line2(geometry, lineMaterial);
  line.computeLineDistances();

  line.material.depthTest = false;

  line.renderOrder = RENDERING_ORDER.LINE;

  return line;
};

export const createClosedThickLine = function ({
  points,
  color = MIDPOINT_COLOR,
  thickness = 4.0,
}) {
  const flatPoints = [];

  for (const point of points) {
    flatPoints.push(point.position.x, point.position.y, point.position.z);
  }

  flatPoints.push(
    points[0].position.x,
    points[0].position.y,
    points[0].position.z
  );

  const geometry = new LineGeometry();
  geometry.setPositions(flatPoints);

  const lineMaterial = new LineMaterial({
    color: color,
    linewidth: thickness,
    dashed: false,
    gapSize: 0.3,
    resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
    transparent: true,
  });

  const line = new Line2(geometry, lineMaterial);
  line.computeLineDistances();

  line.material.depthTest = false;
  line.renderOrder = RENDERING_ORDER.LINE;

  return line;
};

export const updateLinePosition = function (line, points) {
  const flatPoints = [];

  for (const vector of points) {
    flatPoints.push(vector.x, vector.y, vector.z);
  }
  line.geometry.setPositions(flatPoints);

  line.computeLineDistances();
};

export const updateClosedLinePosition = function ({
  line,
  points,
  forceUpdate = false,
}) {
  const flatPoints = [];

  for (const point of points) {
    flatPoints.push(point.position.x, point.position.y, point.position.z);
  }

  flatPoints.push(
    points[0].position.x,
    points[0].position.y,
    points[0].position.z
  );

  const newBufferSize = flatPoints.length;
  const currentBufferSize = line.geometry.attributes.position.array.length;

  if (newBufferSize !== currentBufferSize || forceUpdate) {
    const newGeometry = new LineGeometry();
    newGeometry.setPositions(flatPoints);
    line.geometry.dispose();
    line.geometry = newGeometry;
  } else {
    line.geometry.setPositions(flatPoints);
  }

  line.computeLineDistances();
};

export const getCenterPoint = function (points) {
  const vectorPoints = points.map((point) => point.position);
  return this.getCenterPointFromVectors(vectorPoints);
};

export const getCenterPointFromVectors = function (points) {
  let centerX = 0;
  let centerY = 0;
  let centerZ = 0;

  for (let i = 0; i < points.length; i++) {
    centerX += points[i].x;
    centerY += points[i].y;
    centerZ += points[i].z;
  }

  centerX /= points.length;
  centerY /= points.length;
  centerZ /= points.length;

  return new THREE.Vector3(centerX, centerY, centerZ);
};

export const generateIndices = function (points) {
  const shape = new THREE.Shape();
  const numPoints = points.length;
  let indices = [];

  // Create the shape by connecting points in order
  shape.moveTo(points[0].x, points[0].y);
  for (let i = 1; i < numPoints; i++) {
    shape.lineTo(points[i].x, points[i].y);
  }

  // Triangulate the shape
  const triangles = THREE.ShapeUtils.triangulateShape(points, []);

  for (let i = 0; i < triangles.length; i++) {
    indices.push(triangles[i][0], triangles[i][1], triangles[i][2]);
  }
  return indices;
};

export const calculateTriangleArea = function (v1, v2, v3) {
  const e1 = new THREE.Vector3().subVectors(v2, v1);
  const e2 = new THREE.Vector3().subVectors(v3, v1);
  const crossProduct = new THREE.Vector3().crossVectors(e1, e2);
  const triangleArea = 0.5 * crossProduct.length();
  return triangleArea;
};

export const removeObjectFromScene = function (object) {
  const sceneObject = this.scene.getObjectById(object.id);

  if (!sceneObject) return;

  if (sceneObject.geometry) {
    sceneObject.geometry.dispose();
  }

  if (sceneObject.material) {
    sceneObject.material.dispose();
    if (sceneObject.material.map) sceneObject.material.map.dispose();
  }

  this.scene.remove(sceneObject);
};

export const hideObjectFromScene = function (object) {
  if (!object) return;
  const sceneObject = this.scene.getObjectById(object.id);
  if (sceneObject) sceneObject.visible = false;
};

export const removeObjectWithChildrenFromScene = function (object) {
  const sceneObject = this.scene.getObjectById(object.id);

  if (sceneObject) {
    while (sceneObject.children.length > 0) {
      let child = sceneObject.children[0];
      if (child.geometry) child.geometry.dispose();
      if (child.material) child.material.dispose();
      sceneObject.remove(child);
    }
    // Remove the line group from the scene
    this.scene.remove(sceneObject);

    if (sceneObject.geometry) sceneObject.geometry.dispose();
    if (sceneObject.material) sceneObject.material.dispose();
  }
};

export const removeArrayFromScene = function (objects) {
  objects.forEach((object) => {
    const sceneObject = this.scene.getObjectById(object.id);

    if (sceneObject?.geometry) {
      sceneObject.geometry.dispose();
    }

    if (sceneObject?.material) {
      sceneObject.material.dispose();
    }

    this.scene.remove(sceneObject);
  });
};

export const disableClick = function (event) {
  if (
    this.isShiftDown ||
    this.isAltDown ||
    event.target.tagName !== "CANVAS" ||
    event.button !== 0
  )
    return true;
  else return false;
};

export const setMousePosition = function (event) {
  let canvasBounds = this.renderer.getContext().canvas.getBoundingClientRect();
  this.mouse.x =
    ((event.clientX - canvasBounds.left) /
      (canvasBounds.right - canvasBounds.left)) *
      2 -
    1;
  this.mouse.y =
    -(
      (event.clientY - canvasBounds.top) /
      (canvasBounds.bottom - canvasBounds.top)
    ) *
      2 +
    1;

  this.raycaster.setFromCamera(this.mouse, this.camera);
};

export const restoreDefaultCursor = function () {
  this.renderer.domElement.style.cursor = `default`;
};

export const displayAreaPoint = function (element, isMeasurement) {
  element.position.forEach((point, index) => {
    const areas = isMeasurement ? this.measurementAreas : this.areas;
    const color = isMeasurement ? POINT_COLOR : SOLAR_POINT_COLOR;
    const isFirst = index === 0;
    if (isFirst) areas.push({ points: [], lines: [], element });

    const area = areas[areas.length - 1];
    const dot = this.createReactivePoint(point, color);

    this.scene.add(dot);
    if (!isFirst) {
      let firstPoint = area.points[area.points.length - 1];
      let secondPoint = dot;
      const lineGroup = this.createLineGroup(
        firstPoint,
        secondPoint,
        isMeasurement,
        true
      );
      area.lines.push(lineGroup);
    }

    if (index === element.position.length - 1) {
      let firstPoint = dot;
      let secondPoint = area.points[0];
      // this is for the corupt areas for beta, it should be removed after some time
      if (!secondPoint) {
        secondPoint = dot;
      }
      const lineGroup = this.createLineGroup(
        firstPoint,
        secondPoint,
        isMeasurement,
        true
      );
      area.lines.push(lineGroup);
    }
    area.points.push(dot);
  });
};

export const displayRestrictedAreaPoint = function (element, detectedArea) {
  element.forEach((point, index) => {
    const isFirst = index === 0;
    if (isFirst)
      detectedArea.restrictedAreas.push({ points: [], lines: [], element });

    const area =
      detectedArea.restrictedAreas[detectedArea.restrictedAreas.length - 1];
    const dot = this.createReactivePoint(point, RESTRICTED_AREA_COLOR);

    this.scene.add(dot);
    if (!isFirst) {
      let firstPoint = area.points[area.points.length - 1];
      let secondPoint = dot;

      const firstPointDynamic = new THREE.Vector3();
      const secondPointDynamic = new THREE.Vector3();
      firstPoint.getWorldPosition(firstPointDynamic);
      secondPoint.getWorldPosition(secondPointDynamic);
      let points = [firstPointDynamic, secondPointDynamic];

      const lineGroup = this.createReactiveThickLine(
        points,
        4.0,
        false,
        false,
        RESTRICTED_AREA_COLOR
      );
      this.scene.add(lineGroup);

      const tempLineGroupe = {
        line: lineGroup,
        firstPoint,
        secondPoint,
      };
      area.lines.push(tempLineGroupe);
    }
    if (index === element.length - 1) {
      let firstPoint = dot;
      let secondPoint = area.points[0];

      const firstPointDynamic = new THREE.Vector3();
      const secondPointDynamic = new THREE.Vector3();
      firstPoint.getWorldPosition(firstPointDynamic);
      secondPoint.getWorldPosition(secondPointDynamic);
      let points = [firstPointDynamic, secondPointDynamic];

      const lineGroup = this.createReactiveThickLine(
        points,
        4.0,
        false,
        false,
        RESTRICTED_AREA_COLOR
      );
      this.scene.add(lineGroup);
      const tempLineGroupe = {
        line: lineGroup,
        firstPoint,
        secondPoint,
      };
      area.lines.push(tempLineGroupe);
    }

    area.points.push(dot);
  });
};

export const clearMagnetEffect = function () {
  this.selectedPoint = null;
  this.inMagenticField = false;
};

export const showSnapIcon = function () {
  const snapIcon = this.selectedPoint.children[0];
  snapIcon.element.style.visibility = "visible";
};

export const hideSnapIcon = function () {
  const snapIcon = this.selectedPoint?.children[0];
  if (snapIcon) snapIcon.element.style.visibility = "hidden";
};

export const removeSnapIcon = function (isMeasurement = false) {
  const firstPoint = isMeasurement
    ? this.measurementAreas[this.measurementAreas.length - 1].points[0]
    : this.areas[this.areas.length - 1].points[0];
  const snapIcon = firstPoint.children[1];
  if (snapIcon) {
    firstPoint.remove(snapIcon);
    snapIcon.element.remove();
  }
};

export const changeCursorToCrosshair = function () {
  this.renderer.domElement.style.cursor = "crosshair";
};

export const changeCursorToPointer = function () {
  this.renderer.domElement.style.cursor = "pointer";
};

export const changeCursorToGrab = function () {
  this.renderer.domElement.style.cursor = "grab";
};

export const changeCursorToBlocked = function () {
  this.renderer.domElement.style.cursor = "not-allowed";
};

export const changeCursorToMove = function () {
  this.renderer.domElement.style.cursor = "move";
};

export const getNeighboringPoints = function (pointIndex, area) {
  let neighborPoints = [];

  if (area.points.length < 4) return [];

  if (pointIndex === 0) {
    neighborPoints.push(area.points[area.points.length - 1]);
  } else {
    neighborPoints.push(area.points[pointIndex - 1]);
  }
  if (pointIndex === area.points.length - 1) {
    neighborPoints.push(area.points[0]);
  } else {
    neighborPoints.push(area.points[pointIndex + 1]);
  }
  return neighborPoints;
};

export const checkMergePoints = function (
  pointIndex,
  area,
  isMeasurement = true
) {
  const neighborPoints = this.getNeighboringPoints(pointIndex, area);
  const point = area.points[pointIndex];

  if (neighborPoints.length !== 2) return false;

  let mergedPoints = false;
  neighborPoints.forEach((neighborPoint) => {
    let distance;
    if (isMeasurement) {
      distance = point.position.distanceTo(neighborPoint.position);
    } else {
      const projectedPoint = new THREE.Vector3();
      const vectorPoint = new THREE.Vector3(
        point.position.x,
        point.position.y,
        point.position.z
      );
      area.trianglePlane.projectPoint(vectorPoint, projectedPoint);
      distance = projectedPoint.distanceTo(neighborPoint.position);
    }
    if (distance < 0.3) {
      neighborPoint.merge = true;
      this.mergePoints(pointIndex, neighborPoints, area, isMeasurement);
      mergedPoints = true;

      if (isMeasurement) {
        this.disableMeasurementPointDragMode();
        this.enableMeasurementPointDragMode();
      } else {
        this.disablePointDragMode();
        this.enablePointDragMode();
      }
    }
  });
  return mergedPoints;
};

export const mergePoints = function (
  pointToRemoveIndex,
  neighborPoints,
  area,
  isMeasurement
) {
  const oldPoints = [...area.points];
  this.updatePositionOnObject(
    oldPoints[pointToRemoveIndex],
    this.draggedAreaPoint.lastPosition
  );

  const newPoints = area.points.filter(
    (_, index) => index !== pointToRemoveIndex
  );
  area.points = newPoints;

  const instancedMesh = this.replacePointsWithInstancedMesh(
    newPoints,
    isMeasurement ? POINT_COLOR : SOLAR_POINT_COLOR,
    area.pointInstancedMesh
  );
  this.scene.add(instancedMesh);
  area.pointInstancedMesh = instancedMesh;

  for (let line of area.lines) {
    this.removeObjectFromScene(line.midPoint);
    if (isMeasurement) this.removeObjectFromScene(line.label);
  }

  this.reCreateLinesFromPoints(area, isMeasurement);

  const midpointInstancedMesh = this.replacePointsWithInstancedMesh(
    area.lines.map((line) => line.midPoint),
    isMeasurement ? POINT_COLOR : SOLAR_POINT_COLOR,
    area.midpointInstancedMesh,
    true
  );
  this.scene.add(midpointInstancedMesh);
  area.midpointInstancedMesh = midpointInstancedMesh;

  this.resetMergePoints(neighborPoints);

  this.undoStack.push({
    action: "MERGE_POINT",
    area,
    points: oldPoints,
    isMeasurement,
  });
  this.resetRedoStack();
};

export const reCreateLinesFromPoints = function (area, isMeasurement = true) {
  area.lines = [];
  for (let i = 0; i < area.points.length; i++) {
    let firstPoint = area.points[i];
    let secondPoint =
      i === area.points.length - 1 ? area.points[0] : area.points[i + 1];

    const lineGroup = this.createLineGroup(
      firstPoint,
      secondPoint,
      isMeasurement,
      isMeasurement,
      false,
      true
    );
    area.lines.push(lineGroup);
  }
};

export const resetMergePoints = function (array) {
  for (let item of array) {
    item.merge = false;
  }
};

export const pointBelongsToArea = function (point, area) {
  if (area.points.map((point) => point.uuid).includes(point.uuid)) return true;
  return false;
};

export const replacePointsWithInstancedMesh = function (
  points,
  color = POINT_COLOR,
  instancedMeshToReplace = null,
  isMidpoint = false
) {
  if (instancedMeshToReplace) {
    this.removeObjectFromScene(instancedMeshToReplace);
  }
  const geometry = new THREE.SphereGeometry(isMidpoint ? 0.075 : 0.1, 32, 32);

  let material;
  if (isMidpoint)
    material = this.createShaderMaterialForInstancedMesh(BLACK, color, 0.3);
  else material = this.createShaderMaterialForInstancedMesh(color, BLACK, 0.25);

  const instancedMesh = new InstancedMesh2(
    this.renderer,
    points.length,
    geometry,
    material
  );
  instancedMesh.material.depthTest = false;
  instancedMesh.renderOrder = RENDERING_ORDER.OUTER_POINT;
  instancedMesh.computeBVH();

  const tempPosition = new THREE.Vector3();
  const transformationMatrix = new THREE.Matrix4();

  for (let i = 0; i < points.length; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);

    transformationMatrix.decompose(
      tempPosition,
      new THREE.Quaternion(),
      new THREE.Vector3(1, 1, 1)
    );

    tempPosition.set(
      points[i].position.x,
      points[i].position.y,
      points[i].position.z
    );

    transformationMatrix.compose(
      tempPosition,
      new THREE.Quaternion(),
      new THREE.Vector3(1, 1, 1)
    );
    instancedMesh.setMatrixAt(i, transformationMatrix);

    if (points[i].isObject3D) {
      this.removeObjectWithChildrenFromScene(points[i]);
    }
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
  instancedMesh.computeBVH();

  return instancedMesh;
};

export const combineAreaLines = function (area, color = MIDPOINT_COLOR) {
  for (let line of area.lines) {
    this.removeObjectFromScene(line.line);
  }

  const combinedLine = this.createClosedThickLine({
    points: area.points,
    color,
  });
  this.scene.add(combinedLine);
  area.combinedLine = combinedLine;
};

export const updateAllReactivePoints = function () {
  const openAreas = this.areas.filter((area) => !area.closed);

  for (let area of openAreas) {
    for (let point of area.points) {
      if (point.visible) this.updateReactivePoint(point);
    }

    if (area.lines) {
      for (let line of area.lines) {
        if (line.midPoint && line.midPoint.visible)
          this.updateReactivePoint(line.midPoint, true);
      }
    }
  }

  const closedAreasWithRestrictedAreas = this.areas.filter(
    (area) => area.closed && area.restrictedAreas.length > 0
  );

  for (let area of closedAreasWithRestrictedAreas) {
    const openRestrictedAreas = area.restrictedAreas.filter((ra) => !ra.closed);

    for (let restrictedArea of openRestrictedAreas) {
      for (let point of restrictedArea.points) {
        if (point.visible) this.updateReactivePoint(point);
      }
    }
  }

  const openMeasurementAreas = this.measurementAreas.filter(
    (area) => !area.closed
  );
  for (let area of openMeasurementAreas) {
    for (let point of area.points) {
      if (point.visible) this.updateReactivePoint(point);
    }

    if (area.lines) {
      for (let line of area.lines) {
        if (line.midPoint && line.midPoint.visible)
          this.updateReactivePoint(line.midPoint, true);
      }
    }
  }

  for (let measurement of this.measurements) {
    if (measurement.firstPoint?.visible)
      this.updateReactivePoint(measurement.firstPoint);
    if (measurement.secondPoint?.visible)
      this.updateReactivePoint(measurement.secondPoint);
  }
};

export const updateReactivePoint = function (point, isMidPoint = false) {
  const distance = point.position.distanceTo(this.camera.position);
  const zoomFactor = isMidPoint ? ZOOM_FACTOR : ZOOM_FACTOR + 10;

  const pointScaleFactor = (1 / zoomFactor) * distance;
  point.scale.set(pointScaleFactor, pointScaleFactor, pointScaleFactor);
};

export const updateAllInstancedMeshPoints = function () {
  const closedAreas = this.areas.filter((area) => area.closed);

  for (let area of closedAreas) {
    if (area.pointInstancedMesh)
      this.updateInstancedMeshPoints(area.pointInstancedMesh);
    if (area.midpointInstancedMesh)
      this.updateInstancedMeshPoints(area.midpointInstancedMesh);

    const closedRestrictedAreas = area.restrictedAreas.filter(
      (ra) => ra.closed
    );
    for (let restrictedArea of closedRestrictedAreas) {
      if (restrictedArea.instancedMesh)
        this.updateInstancedMeshPoints(restrictedArea.instancedMesh);
    }
  }

  const closedMeasurementAreas = this.measurementAreas.filter(
    (area) => area.closed
  );

  for (let area of closedMeasurementAreas) {
    if (area.pointInstancedMesh)
      this.updateInstancedMeshPoints(area.pointInstancedMesh);
    if (area.midpointInstancedMesh)
      this.updateInstancedMeshPoints(area.midpointInstancedMesh);
  }
};

export const updateInstancedMeshPoints = function (instancedMesh) {
  const tempPosition = new THREE.Vector3();
  const transformationMatrix = new THREE.Matrix4();
  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);
    transformationMatrix.decompose(
      tempPosition,
      new THREE.Quaternion(),
      new THREE.Vector3(1, 1, 1)
    );
    const distance = tempPosition.distanceTo(this.camera.position);
    const scaleFactor = (1 / (ZOOM_FACTOR + 10)) * distance;
    transformationMatrix.compose(
      tempPosition,
      new THREE.Quaternion(),
      new THREE.Vector3(scaleFactor, scaleFactor, scaleFactor)
    );
    instancedMesh.setMatrixAt(i, transformationMatrix);
  }
  instancedMesh.instanceMatrix.needsUpdate = true;
  instancedMesh.computeBVH();
};

export const shouldThrottle = function () {
  const currentTime = performance.now();
  if (currentTime - this.lastUpdateTime < this.throttleInterval) return true;
  this.lastUpdateTime = currentTime;
  return false;
};

export const addPointToScene = function (position, color) {
  const SphereGeometry = new THREE.SphereGeometry(0.1, 32, 32);
  const material = new THREE.MeshBasicMaterial({ color });
  const point = new THREE.Mesh(SphereGeometry, material);
  point.position.copy(position);
  this.scene.add(point);
  point.material.depthTest = false;
  point.renderOrder = 9999999;
};

export const setPointColor = function (point, color) {
  point.material.uniforms.innerColor.value.set(color);
  point.material.uniforms.innerColor.needsUpdate = true;
};

export const setLineColor = function (line, color) {
  line.material.color.setHex(color);
};
